import React from "react";
import { cn } from "../utils/cn";

export const BentoGridSkeletoon = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        "grid md:auto-rows-[18rem] grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto ",
        className
      )}
    >
      {children}
    </div>
  );
};

function NewGrid() {
  return (
    <div className="row-span-1 flex flex-col items-start justify-start w-full h-[275px] p-3 rounded-[20px] border-[1.85px] border-gray-200">
      <div className="w-full h-[65%] rounded-[20px] bg-gray-200 animate-pulse overflow-hidden"></div>
      <div className="my-2 w-[35%] h-[5%] rounded-full bg-gray-200 animate-pulse"></div>
      <div className="w-[80%] h-[20%] rounded-[14px] bg-gray-200 animate-pulse"></div>
    </div>
  );
}

export default NewGrid;
