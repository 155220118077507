import "./index.css";
import AboutUs from "./sections/AboutUs";
import Presentation from "./sections/Presentation";

import { createRoot } from "react-dom/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Services from "./sections/Services";
import Products from "./sections/Products";
import Software from "./sections/Software";
import OL from "./sections/OL";
import News from "./sections/News";
import New from "./sections/New";
import ContactUs from "./sections/ContactUs";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Router>
    <Routes>
      <Route path="/news" element={<News />} />
      <Route path="/new/:id" element={<New />} />

      <Route path="/outsourcing-and-leasing" element={<OL />} />
      <Route path="/gabs" element={<Products />} />
      <Route path="/" element={<Presentation />} />
      <Route path="*" element={<Presentation />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/services" element={<Services />} />
      <Route path="/software" element={<Software />} />
      <Route path="/contact-us" element={<ContactUs />} />
    </Routes>
  </Router>
);
