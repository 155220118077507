import React, { useEffect } from "react";
import {
  Pagination,
  Navigation,
  Autoplay,
  EffectCreative,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-creative";
import "./styles/slider.css";
import "../index.css";
import { New } from "../sections/News";
import { useNavigate } from "react-router-dom";
import Nothing from "../assets/lotties/nothing.json";
import Lottie from "lottie-react";

const swiperData = [
  {
    title: "Slide 1",
    description: "Description for slide 1",
    imageUrl:
      "https://www.burvalcorporate.com/wp-content/uploads/2021/02/Slide1-03.jpg",
  },
  {
    title: "Slide 2",
    description: "Description for slide 2",
    imageUrl:
      "https://www.burvalcorporate.com/wp-content/uploads/2020/12/vlcsnap-2020-12-27-02h26m43s508.png",
  },
  {
    title: "Slide 3",
    description: "Description for slide 3",
    imageUrl:
      "https://www.apple.com/v/iphone/home/bp/images/meta/iphone__ky2k6x5u6vue_og.png",
  },
  {
    title: "Slide 4",
    description: "Description for slide 4",
    imageUrl:
      "https://www.apple.com/v/iphone/home/bp/images/meta/iphone__ky2k6x5u6vue_og.png",
  },
  {
    title: "Slide 5",
    description: "Description for slide 5",
    imageUrl:
      "https://www.apple.com/v/iphone/home/bp/images/meta/iphone__ky2k6x5u6vue_og.png",
  },
];

function parseHtml(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

function Slider({ news, isLoading }: { news: New[]; isLoading: boolean }) {
  const navigate = useNavigate();

  function covertToImageStr(value: string | ArrayBuffer | null) {
    if (typeof value === "string") {
      return value;
    } else if (isArrayBuffer(value)) {
      const blob = new Blob([value]);
      const url = URL.createObjectURL(blob);
      return url;
    } else {
      return "no-image.png";
    }
  }

  const isArrayBuffer = (value: any): value is ArrayBuffer => {
    return (
      value &&
      typeof ArrayBuffer !== "undefined" &&
      value instanceof ArrayBuffer
    );
  };

  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      navigation={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      grabCursor={true}
      effect={"creative"}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: ["-20%", 0, -1],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      }}
      modules={[Pagination, Navigation, Autoplay, EffectCreative]}
      className="max-w-full h-full border-shadow rounded-xl"
    >
      {isLoading && (
        <SwiperSlide
          style={{ backgroundColor: "#c1c1c1" }}
          className="gradient-swiper w-full h-full"
        >
          <div className="animate-pulse gradient-swiper"></div>
        </SwiperSlide>
      )}
      {!isLoading &&
        news.map((slide, index) => (
          <SwiperSlide
            onClick={(e) => navigate(`/new/${slide.id}`)}
            key={index}
            className={`relative swiper-slide *:hover:cursor-pointer`}
          >
            <div className="absolute z-[-1] w-full h-full">
              <img
                className="w-full h-full object-cover"
                src={covertToImageStr(slide.head[2].image)}
                alt=""
              />
            </div>
            <div className="absolute w-full h-full z-[1] bg-[#000000a7]"></div>
            <div className="swiper-content z-[2] text-start w-[87.5%] h-full flex flex-col items-start justify-center">
              <h1 className="text-[6vw] text-ellipsis-2-lines sm:text-[22.5px] md:text-[30px] lg:text-[35px] text-white mb-[1em]">
                {parseHtml(slide.head[0].html)}
              </h1>
              <p className="text-[4vw] lg:text-[20px] text-white text-ellipsis-2-lines">
                {parseHtml(slide.head[1].html)}
              </p>
            </div>
          </SwiperSlide>
        ))}
      {!isLoading && news.length === 0 && (
        <SwiperSlide>
          <Lottie
            className="w-[30vw] md:w-[20vw] h-auto"
            animationData={Nothing}
            loop={true}
          />
        </SwiperSlide>
      )}
      {/*  */}
      {/* {swiperData.map((slide, index) => (
        <SwiperSlide
          key={index}
          className="swiper-slide"
          style={{ backgroundImage: `url(${slide.imageUrl})` }}
        >
          <div className="dark-overlay"></div>
          <div className="swiper-content">
            <h2 className="text-[30px] text-white">{slide.title}</h2>
            <p className="text-[20px] text-white">{slide.description}</p>
          </div>
        </SwiperSlide>
      ))} */}
    </Swiper>
  );
}

export default Slider;
