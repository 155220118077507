import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  Timestamp,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { app, database } from "../config";

const auth = getAuth(app);
const storage = getStorage();

type content = {
  id: string;
  html: string;
  label: string;
  target: "head" | "body";
  image: ArrayBuffer | null;
  type: "Titre" | "Paragraphe" | "Image";
};

type New = {
  id: string;
  head: content[];
  body: content[];
  publishedAt: Timestamp;
};

// function formatTitle(inputString: string): string {
//   const lowercaseString = inputString.toLowerCase();

//   return lowercaseString.replace(/\s+/g, "-");
// }

// export const deleteDocumentById = async (
//   id: string,
//   activeToastWithStatus: (message: string, success: boolean) => void
// ) => {
//   try {
//     const documentRef = doc(database, "news", id);
//     await deleteDoc(documentRef);
//     activeToastWithStatus("L'article a été supprimé.", true);
//   } catch (error) {
//     console.error("Erreur lors de la suppression du document :", error);
//     activeToastWithStatus(
//       "Une erreur s'est produite lors de la suppression de l'article.",
//       false
//     );
//   }
// };

// export const getOnlyNew = async (
//   id: string,
//   setHead: React.Dispatch<React.SetStateAction<any>>,
//   setBody: React.Dispatch<React.SetStateAction<any>>,
//   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
// ) => {
//   // console.log("Here");
//   // alert("Here");
//   console.warn("Critical Firebase Function");
//   setIsLoading(true);
//   try {
//     const documentRef = doc(database, "news", id);
//     const documentSnapshot = await getDoc(documentRef);

//     setHead([]);
//     setBody([]);
//     if (documentSnapshot.exists()) {
//       const documentData = documentSnapshot.data();

//       setHead(documentData.head);
//       setBody(documentData.content);
//     } else {
//       alert("Aucune actualité n'a été trouvée");
//     }
//     setIsLoading(false);
//   } catch (error) {
//     setIsLoading(false);
//     alert("Une erreur s'est produite.");
//     console.error("Erreur lors de la récupération du document :", error);
//   }
// };

// export const getNew = async (
//   id: string,
//   setNew: React.Dispatch<React.SetStateAction<New | null>>,
//   setTopFive: React.Dispatch<React.SetStateAction<New[]>>,
//   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
// ) => {
//   try {
//     const documentRef = doc(database, "news", id);
//     const documentSnapshot = await getDoc(documentRef);

//     if (documentSnapshot.exists()) {
//       const query_to_five_news = query(
//         collection(database, "news"),
//         orderBy("publishedAt", "desc"),
//         limit(5)
//       );

//       getDocs(query_to_five_news)
//         .then((querySnapshot) => {
//           const documentData = documentSnapshot.data();

//           setNew({
//             id: documentData.id,
//             head: documentData.head,
//             body: documentData.content,
//             publishedAt: documentData.publishedAt,
//           });

//           setTopFive([]);
//           querySnapshot.forEach((doc) => {
//             const NewData = doc.data();

//             const data: New = {
//               id: NewData.id,
//               body: NewData.body,
//               head: NewData.head,
//               publishedAt: NewData.publishedAt,
//             };

//             id !== data.id && setTopFive((old: New[]) => [...old, data]);
//           });
//         })
//         .catch((error) => {
//           console.error("Erreur lors de la récupération des news : ", error);
//         })
//         .finally(() => {
//           setIsLoading(false);
//         });
//     } else {
//       setIsLoading(false);
//       alert("Aucune actualité n'a été trouvée");
//       return null;
//     }
//   } catch (error) {
//     setIsLoading(false);
//     alert("Une erreur s'est produite.");
//     console.error("Erreur lors de la récupération du document :", error);
//   }
// };

export const getNews = (
  setNews: React.Dispatch<React.SetStateAction<New[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  console.warn("Critical Firebase Function");
  setIsLoading(true);
  const query_news = query(collection(database, "news"));

  setNews([]);
  getDocs(query_news)
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const NewData = doc.data();

        const data: New = {
          id: NewData.id,
          body: NewData.content,
          head: NewData.head,
          publishedAt: NewData.publishedAt,
        };

        setNews((old: New[]) => [...old, data]);
      });
    })
    .catch((error) => {
      console.error("An error occur:", error);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const getNew = async (
  id: string,
  setNew: React.Dispatch<React.SetStateAction<New | null>>,
  setTopFive: React.Dispatch<React.SetStateAction<New[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  console.warn("Critical Firebase Func");
  setIsLoading(true);
  try {
    const documentRef = doc(database, "news", `${id}`);
    const documentSnapshot = await getDoc(documentRef);

    if (documentSnapshot.exists()) {
      const query_to_five_news = query(
        collection(database, "news"),
        orderBy("publishedAt", "desc"),
        limit(5)
      );

      getDocs(query_to_five_news)
        .then((querySnapshot) => {
          const documentData = documentSnapshot.data();

          setNew({
            id: documentData.id,
            head: documentData.head,
            body: documentData.content,
            publishedAt: documentData.publishedAt,
          });

          setTopFive([]);
          querySnapshot.forEach((doc) => {
            const NewData = doc.data();

            const data: New = {
              id: NewData.id,
              body: NewData.body,
              head: NewData.head,
              publishedAt: NewData.publishedAt,
            };

            id !== data.id && setTopFive((old: New[]) => [...old, data]);
          });
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des news : ", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      alert("Aucune actualité n'a été trouvée");
      return null;
    }
  } catch (error) {
    setIsLoading(false);
    alert("Une erreur s'est produite.");
    console.error("Erreur lors de la récupération du document :", error);
  }
};

// const saveToDatabase = async (
//   title: string,
//   head: content[],
//   body: content[],
//   url: string,
//   activeToast: (message: string, success: boolean) => void,
//   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
//   id?: string
// ) => {
//   const docRef = doc(database, "news", `${title}`);

//   await setDoc(docRef, {
//     id: title,
//     head: [head[0], head[1], { ...head[2], image: url }],
//     content: body,
//     publishedAt: new Date(),
//   })
//     .then(() => {
//       activeToast("L'actualité vient d'etre publiée.", true);
//     })
//     .catch(() => {
//       activeToast("Vous n'etes pas authentifié.", false);
//     })
//     .finally(() => {
//       setIsLoading(false);
//     });
// };

// export const save = (
//   head: content[],
//   body: content[],
//   activeToast: (message: string, success: boolean) => void,
//   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
//   id?: string
// ) => {
//   setIsLoading(true);

//   if (head[2].image === null) {
//     activeToast(
//       "Une erreur est survenu lors de la lecture de l'image. Veuillez re-essayer avec une autre image.",
//       false
//     );
//     console.log(head);
//     setIsLoading(false);
//     return;
//   }

//   onAuthStateChanged(auth, (user) => {
//     if (user) {
//       const parser = new DOMParser();
//       const doc = parser.parseFromString(head[0].html, "text/html");
//       const text = doc.body.textContent || "";
//       const title = formatTitle(text);

//       const storageRef = ref(storage, `media/images/${title}/cover.png`);

//       const metadata = {
//         contentType: "application/png",
//       };

//       const uploadTask =
//         head[2].image &&
//         uploadBytesResumable(storageRef, head[2].image, metadata);

//       if (!uploadTask) return;

//       uploadTask.on(
//         "state_changed",
//         (snapshot) => {
//           const progress =
//             (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         },
//         (error) => {
//           activeToast(
//             "Une erreur est survenu. Veuillez re-essayer plus tard.",
//             false
//           );
//           setIsLoading(false);
//         },
//         async () => {
//           const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
//           if (id) {
//             saveToDatabase(
//               title,
//               head,
//               body,
//               downloadURL,
//               activeToast,
//               setIsLoading,
//               id
//             );
//           } else {
//             saveToDatabase(
//               title,
//               head,
//               body,
//               downloadURL,
//               activeToast,
//               setIsLoading
//             );
//           }
//         }
//       );
//     } else {
//       activeToast("Vous n'etes pas authentifié.", false);
//       setIsLoading(false);
//     }
//   });
// };
