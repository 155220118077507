import React, { useEffect, useRef, useState } from "react";
import TopBar from "../components/TopBar";
import BayBowLogo from "../assets/images/logos/baybow.png";
import { MacbookScroll } from "../components/ui/mackbook-scroll";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import Chevron from "../components/Chevron";

const images = [
  "https://images.pexels.com/photos/4559668/pexels-photo-4559668.jpeg",
  "https://images.pexels.com/photos/7971190/pexels-photo-7971190.jpeg",
  "https://images.pexels.com/photos/7988079/pexels-photo-7988079.jpeg",
];

function Software() {
  const location = useLocation();
  const sections = useRef<HTMLElement[]>([]);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollto");

    if (scrollTo) {
      const element = document.getElementById(scrollTo);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - 60,
          behavior: "smooth",
        });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY + window.innerHeight / 2;
      const index = sections.current.findIndex(
        (section) =>
          currentScroll >= section.offsetTop &&
          currentScroll <= section.offsetTop + section.offsetHeight
      );
      setCurrentSectionIndex(index);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    sections.current = Array.from(
      document.querySelectorAll(".section-p-sticky")
    );
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <TopBar />
      <Chevron />

      <section className="relative bg-white z-10 overlay flex flex-col items-center justify-center w-full min-h-screen bg-transparent px-[1.25rem] py-section-small md:py-section md:px-[5.5em]">
        <div className="absolute z-10 bg-[#0000007e] top-0 left-0 w-full h-full"></div>
        <div className="absolute top-0 left-0 min-w-full h-full z-[-1]">
          <video
            autoPlay
            muted
            loop
            playsInline
            poster="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/miniatures%2Fbaybow_notre_suite_logicielle.png?alt=media&token=dbc0ebd8-4e4d-47c0-9126-7c9e75eb4815"
            className="w-full h-full object-cover"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/videos%2Fbaybow_notre_suite_logicielle.mp4?alt=media&token=bffe58c4-c6ab-4446-b2b5-55a5b235c978"
              type="video/mp4"
            />
            Votre navigateur ne prend pas en charge la lecture de vidéos.
          </video>
        </div>
        <div className="absolute top-0 left-0 min-w-full h-full z-[-2]">
          <img
            className="w-full h-full object-cover"
            src="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/miniatures%2Fbaybow_notre_suite_logicielle.png?alt=media&token=dbc0ebd8-4e4d-47c0-9126-7c9e75eb4815"
            alt=""
          />
        </div>
        <div className="z-30 flex flex-col items-center justify-center w-full lg:max-w-[1000px]">
          <h1 className="text-center md:text-pretty font-[500] *:text-white">
            <span className="font-[500]">Des</span>{" "}
            <span className="gradient-white font-[500]">outils</span>{" "}
            <span className="font-[500]">au service de la</span>{" "}
            <span className="gradient-white-inversed font-[500]">
              banque de demain
            </span>
          </h1>
          <p className="text-white text-base text-center md:w-full mx-auto text-[18px] font-[400] my-[1em]">
            Un software flexible et évolutif pour répondre à vos besoins
            spécifiques.
          </p>
          <div className="btns gap-[1.5em] my-10 flex flex-wrap items-center justify-center w-full px-3 md:px-0">
            <a href="#macbook" className="*:hover:cursor-pointer">
              <button className="*:hover flex items-center justify-center rounded-full py-[12px] px-[16px] bg-blue-baybow">
                <label className="cursor-pointer text-white mr-[5px] text-[16px] font-[500]">
                  Voir notre suite logicielle
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="bi bi-currency-dollar text-white fill-white w-[22px] h-[22px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                  />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </section>

      <section id="macbook" className="w-full overflow-hidden">
        <MacbookScroll
          title="Plongez dans l'univers du digital avec Baybow."
          badge={false}
        />
      </section>

      <div className="bg-white grid w-full lg:w-[85%]">
        <div className="img place-self-center w-[100%] overflow-x-hidden lg:w-full z-10 flex items-start lg:items-center lg:justify-center sticky mt-[15%] lg:mt-0 top-[50px] lg:top-0 left-0 h-[100vh]">
          <div className="absolute shadow-container left-0 flex items-center justify-center w-full lg:w-[350px] h-[28vh] lg:h-[450px] translate-x-[7.5%] lg:translate-x-0">
            <img
              className="w-full h-full object-cover"
              src={
                currentSectionIndex === -1
                  ? images[0]
                  : images[currentSectionIndex]
              }
              alt=""
            />
          </div>
        </div>

        <div className="mt-[-100vh] place-self-center w-[85%] lg:w-full">
          <section className="section-p-sticky w-full lg:h-screen flex items-start lg:items-center justify-end">
            <div className="w-full lg:w-[45%] mt-[calc(28vh+55px)] lg:mt-0 flex flex-col items-start justify-center">
              <h3 className="text-[30px] font-[500] text-black mb-3">
                Des solutions logicielles au plus près de vos transformations
              </h3>
              <p className="text-[16px] text-gray-500">
                Notre gamme de software vous accompagne dans le développement de
                services innovants et d'une relation client personnalisée.
              </p>
            </div>
          </section>
          <section className="section-p-sticky w-full h-screen flex items-start lg:items-center justify-end">
            <div className="w-full lg:w-[45%] mt-[calc(28vh+55px)] lg:mt-0 flex flex-col items-start justify-center">
              <h3 className="text-[30px] font-[500] text-black mb-3">
                Optimisation, sécurité et souplesse
              </h3>
              <p className="text-[16px] text-gray-500">
                Notre suite logicielle vous permet d'exploiter pleinement le
                potentiel de la banque intelligente.
              </p>
            </div>
          </section>
          <section className="section-p-sticky w-full min-h-screen flex items-start lg:items-center justify-end">
            <div className="w-full lg:w-[45%] mt-[calc(28vh+55px)] lg:mt-0 flex flex-col items-start justify-center">
              <h3 className="text-[30px] font-[500] text-black mb-3">
                Personnalisation et services sur-mesure
              </h3>
              <p className="text-[16px] text-gray-500">
                Avec nos software modulaires, nous vous proposons des services
                simples et réactifs, adaptés aux attentes de
                chacun de vos clients.
              </p>
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Software;
