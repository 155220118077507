import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB_n9icHh92gJttkAOGqAhCpBvTwGN8rvg",
  authDomain: "baybow-1768d.firebaseapp.com",
  projectId: "baybow-1768d",
  storageBucket: "baybow-1768d.appspot.com",
  messagingSenderId: "54853779258",
  appId: "1:54853779258:web:f4117c59d1c4d707ceda03",
  measurementId: "G-N90CLZ46MQ",
};

export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
export const analytics = getAnalytics(app);
