import { cn } from "../../utils/cn";
import "../../index.css";
import { New } from "../../sections/News";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export const BentoGrid = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        "grid md:auto-rows-[18rem] grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto ",
        className
      )}
    >
      {children}
    </div>
  );
};

function parseHtml(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

function formatFirebaseTimestamp(timestamp: Timestamp): string {
  const date = new Date(timestamp.seconds * 1000);

  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  const days = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];

  const day = days[date.getUTCDay()];
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const formattedDate = `${date.getUTCDate()} ${month} ${year} à ${hours}h${minutes} GMT`;

  return formattedDate;
}

export const BentoGridItem = ({
  className,
  icon,
  item,
}: {
  className?: string;
  icon?: React.ReactNode;
  item: New;
}) => {
  const navigate = useNavigate();

  function convertToStr(value: string | ArrayBuffer | null) {
    if (typeof value === "string") {
      return value;
    } else if (isArrayBuffer(value)) {
      const blob = new Blob([value]);
      const url = URL.createObjectURL(blob);
      return url;
    } else {
      return "no-image.png";
    }
  }

  const isArrayBuffer = (value: any): value is ArrayBuffer => {
    return (
      value &&
      typeof ArrayBuffer !== "undefined" &&
      value instanceof ArrayBuffer
    );
  };
  return (
    <div
      onClick={(e) => navigate(`/new/${item.id}`)}
      className={cn(
        "*:hover:cursor-pointer row-span-1 rounded-xl group/bento transition duration-200 dark:shadow-none p-4 dark:bg-black dark:border-white/[0.2] bg-white justify-between flex flex-col space-y-4",
        className
      )}
    >
      <div className="flex rounded-xl overflow-hidden flex-1 w-full h-full min-h-[6rem] max-h-[9rem] lg:max-h-auto bg-gradient-to-br from-neutral-200 dark:from-neutral-900 dark:to-neutral-800 to-neutral-100">
        <img
          className="object-cover hover:scale-[1.15] duration-200 z-40 w-full h-full"
          src={item.head && convertToStr(item.head[2].image)}
          alt=""
        />
      </div>
      <div className="group-hover/bento:translate-x-2 transition duration-200">
        {icon}
        <div className="font-sans text-[20px] text-ellipsis truncate font-bold text-neutral-600 dark:text-neutral-200 mb-2 mt-2">
          {item.head && parseHtml(item.head[0].html)}
        </div>
        <div className="font-sans text-[16px] text-ellipsis truncate font-normal text-neutral-600 dark:text-neutral-300 w-full">
          {item.head && parseHtml(item.head[1].html)}
        </div>
        <span className="font-sans text-[12px] text-gray-400 mt-2">
          Publié le {formatFirebaseTimestamp(item.publishedAt)}
        </span>
      </div>
    </div>
  );
};
