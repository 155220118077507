import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BBSLogo from "../assets/images/logos/bbs-logo.png";
import Chevron from "../components/Chevron";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";
import { analytics } from "../firebase/config";
import "../index.css";
import { logEvent } from "firebase/analytics";

const products = [
  {
    name: "Installation et Maintenance",
    description: "Description de l'installation et de la maintenance.",
    url: "/services",
  },
  {
    name: "DAB/GAB",
    description: "Description de DAB/GAB.",
    url: "/gabs?scrollto=DAB/GAB",
  },
  {
    name: "CDM",
    description: "Description de CDM.",
    url: "/gabs?scrollto=P2600E",
  },
  {
    name: "Suite logicielle",
    description: "Description de la suite logicielle.",
    url: "/software",
  },
  {
    name: "Outsourcing, Leasing",
    description: "Description de l'outsourcing et du leasing.",
    url: "/outsourcing-and-leasing",
  },
];

const products_card = [
  {
    title: "Nos services",
    route: "services",
    description:
      "De l'installation à la maintenance en passant par le conseil, nous vous accompagnons à chaque étape.",
    image:
      "https://images.pexels.com/photos/5952738/pexels-photo-5952738.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    title: "Notre gamme d'automates",
    route: "gabs",
    description:
      "Des produits innovants pour améliorer l'expérience de vos clients et optimiser vos opérations.",
    image:
      "https://images.pexels.com/photos/8555256/pexels-photo-8555256.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    title: "Notre suite logicielle",
    route: "software",
    description:
      "Un software flexible et évolutif pour répondre à vos besoins spécifiques.",
    image:
      "https://images.pexels.com/photos/5935791/pexels-photo-5935791.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    title: "Outsourcing & Leasing",
    route: "outsourcing-and-leasing",
    description:
      "La solution clé en main pour vos guichets automatiques de banque.",
    image: "https://images.pexels.com/photos/4559960/pexels-photo-4559960.jpeg",
  },
];

function Presentation() {
  const location = useLocation();
  const navigate = useNavigate();
  const sections = useRef<HTMLElement[]>([]);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY + window.innerHeight / 2;
      const index = sections.current.findIndex(
        (section) =>
          currentScroll >= section.offsetTop &&
          currentScroll <= section.offsetTop + section.offsetHeight
      );
      setCurrentSectionIndex(index);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollto");

    if (scrollTo) {
      const element = document.getElementById(scrollTo);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - 60,
          behavior: "smooth",
        });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const images = [
    "https://images.pexels.com/photos/18964510/pexels-photo-18964510/free-photo-of-ville-building-batiment-immeuble.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/5699376/pexels-photo-5699376.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/8554373/pexels-photo-8554373.jpeg?auto=compress&cs=tinysrgb&w=600",
  ];

  useEffect(() => {
    sections.current = Array.from(
      document.querySelectorAll(".section-p-sticky")
    );
  }, []);

  useEffect(() => {
    const buttons = document.querySelectorAll(".button-wrapper");
    let index = 0;

    const animateProgressBar = () => {
      const button = buttons[index];

      const text = button.querySelector(".button-wrapper-text") as HTMLElement;
      const pb = button.querySelector(".progress-bar") as HTMLElement;
      const progressBar = button.querySelector(
        ".progress-bar-fill"
      ) as HTMLElement;

      if (progressBar) {
        progressBar.style.opacity = "1";
        progressBar.style.width = "100%";
        pb.style.display = "flex";
        text.style.color = "white";

        setTimeout(() => {
          progressBar.style.width = "0%";
          progressBar.style.opacity = "0";
          pb.style.display = "none";
          text.style.color = "#d9d9d9c8";

          index = (index + 1) % buttons.length;
          animateProgressBar();
        }, 3000);
      }
    };

    animateProgressBar();

    return () => {
      document.querySelectorAll(".progress-bar-fill").forEach((bar) => {
        if (bar instanceof HTMLElement) {
          bar.style.width = "0";
        }
      });
    };
  }, []);

  const seeProducts = () => {
    logEvent(analytics, "see_products_from_landing_page", {
      description:
        "Utilisateur a clické sur le bouton 'Voir les produits' depuis la page principale.",
    });
  };

  return (
    <div className="w-full h-full -pt-[56px] lg:-pt-[66px] flex flex-col items-center justify-center">
      <TopBar />
      <Chevron />
      <section className="relative bg-white z-10 overlay flex flex-col items-center justify-start w-full min-h-screen bg-transparent px-[1.25rem] py-section-small md:py-section md:px-[5.5em]">
        <div className="absolute z-10 bg-[#0000007e] top-0 left-0 w-full h-full"></div>
        <div className="absolute top-0 left-0 min-w-full h-full z-[-1]">
          <video
            autoPlay
            muted
            loop
            playsInline
            poster="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/miniatures%2Fbaybow_accueil.png?alt=media&token=0a2d8b1c-c1e8-4b73-b7a6-2139e6c56c58"
            className="w-full h-full object-cover"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/videos%2Fbaybow_accueil.mp4?alt=media&token=2c8f43a3-8471-4823-83b9-877d7f58cd8d"
              type="video/mp4"
            />
            Votre navigateur ne prend pas en charge la lecture de vidéos.
          </video>
        </div>
        <div className="absolute top-0 left-0 min-w-full h-full z-[-2]">
          <img
            className="w-full h-full object-cover"
            src="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/miniatures%2Fbaybow_accueil.png?alt=media&token=0a2d8b1c-c1e8-4b73-b7a6-2139e6c56c58"
            alt=""
          />
        </div>
        <div className="z-30 mt-[4.5rem] flex flex-col items-center justify-center w-full lg:max-w-[1000px]">
          <h1 className="text-center md:text-pretty font-[500] *:text-white">
            <span className="gradient-white font-[500]">La solution</span>{" "}
            <span className="font-[500]">clé en main pour la</span>{" "}
            <span className="gradient-white-inversed font-[500]">
              banque digitale
            </span>
          </h1>

          <p className="text-white text-base text-center md:w-full mx-auto text-[18px] font-[400] my-[1em]">
            Optimisez vos opérations et sécurisez vos transactions avec nos
            solutions hardware et software.
          </p>
          <div className="flex items-center justify-center mt-[2.5em] gap-[1.5em]">
            <a href="#products-cards">
              <button
                onClick={seeProducts}
                className="*:hover:cursor-pointer flex items-center justify-center rounded-full py-[12px] px-[16px] bg-blue-baybow"
              >
                <span className="text-white hover:cursor-pointer mr-[5px] text-[16px] font-[500]">
                  Voir nos produits
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="bi bi-currency-dollar text-white fill-white w-[22px] h-[22px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                  />
                </svg>
              </button>
            </a>
          </div>
        </div>
        <div className="text-[#d9d9d9c8] z-30 flex flex-wrap items-center justify-center mt-14 font-[400] leading-6 text-base">
          <span className="text-[#d9d9d9c8]">Baybow est expert en&nbsp;</span>

          {products.map((product, index) => (
            <Link to={product.url}>
              <div className="flex items-center">
                <button
                  key={index}
                  className="button-wrapper hover:cursor-pointer relative"
                >
                  <label className="text-[#d9d9d9c8] hover:cursor-pointer hover:text-white duration-200 button-wrapper-text mt-10">
                    {product.name}
                  </label>
                  <div className="progress-bar hidden absolute bottom-0 left-0 w-full h-[1.35px] bg-[#d9d9d9c841]"></div>
                  <div className="progress-bar-fill w-0 absolute bottom-0 left-0 h-[1.35px] bg-white"></div>
                </button>
                {index !== products.length - 1 ? (
                  <span className="text-[#d9d9d9c8]">,&nbsp;</span>
                ) : (
                  <span className="text-[#d9d9d9c8]">.</span>
                )}
              </div>
            </Link>
          ))}
        </div>
      </section>

      <section className="flex items-center justify-center w-full h-auto py-section-small md:py-section bg-[#f1f4f8]">
        <ul className="flex items-center justify-center w-[90%] md:w-[85%] h-auto gap-4 lg:gap-[15vw]">
          <a
            href="https://global.grgbanking.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li className="flex items-center justify-center w-[80px] sm:w-[9.65vw] lg:w-[7.15vw]  h-auto overflow-hidden">
              <img
                className="object-cover w-full h-full"
                src="https://ebmcq.com/img/Partners/grg_banking.png"
                alt="Logo 1"
              />
            </li>
          </a>
          <a
            href="https://www.bbsholding.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li className="flex items-center justify-center w-[80px] sm:w-[9.65vw] lg:w-[7.15vw] h-auto overflow-hidden">
              <img
                className="object-cover w-full h-full"
                src={BBSLogo}
                alt="Logo 2"
              />
            </li>
          </a>
          <a
            href="https://www.burvalcorporate.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li className="flex items-center justify-center w-[80px] sm:w-[9.65vw] lg:w-[7.15vw] h-auto overflow-hidden">
              <img
                className="object-cover w-full h-full"
                src="https://firebasestorage.googleapis.com/v0/b/brocand-test.appspot.com/o/movies%2Fburval.png?alt=media&token=e93ac60a-17a9-4c9b-9dc6-ab7fe6c7d493"
                alt="Logo 3"
              />
            </li>
          </a>
        </ul>
      </section>

      <section
        id="products-cards"
        className="flex flex-col items-center justify-center bg-white w-full h-auto px-[1.25rem] py-section-small md:py-section md:px-[5.5em]"
      >
        <div className="flex flex-col items-center justify-between bg-white w-full md:w-[85%]">
          <div className="top flex items-center justify-center w-full mb-[2.5em]">
            <div className="left flex flex-col items-center justify-center">
              <h2 className="text-black text-balance font-[500] text-[1.75rem] md:text-[50px] mb-[2px]">
                Nos{" "}
                <span className="gradient text-[1.75rem] md:text-[50px]">
                  expertises
                </span>{" "}
              </h2>
              <p className="text-black text-center text-base text-balance w-auto md:w-full text-[18px] font-[400] mx-auto my-[1em]">
                Réinventez vos services financiers avec nos solutions
                innovantes, de la fourniture d'équipement à l'optimisation
                opérationnelle
              </p>
            </div>
          </div>

          <div className="bottom relative gap-7 flex flex-wrap flex-col lg:flex-row items-center lg:items-start justify-evenly w-full h-auto">
            {products_card.map((product, index) => (
              <a
                key={index}
                href={`https://baybow.asia/${product.route}`}
                className="card overflow-hidden w-full lg:w-[45%] max-w-[400px] md:min-w-[500px] md:max-w-[525px] lg:max-w-[600px] h-[300px] lg:h-[190px] rounded-[8px] border-shadow flex flex-col lg:flex-row items-start justify-between"
              >
                <div className="w-full lg:max-w-[60%] h-auto lg:h-full p-5 py-6">
                  <span className="text-[20px] text-blue-baybow font-[600]">
                    {product.title}
                  </span>
                  <p className="text-[14px] text-[#1D1D1D] font-[500] mt-[0.65em]">
                    {product.description}
                  </p>
                </div>

                <div className="overflow-hidden mt-1 lg:mt-0 lg:right-0 lg:top-0 w-full lg:w-[30%] flex items-center justify-center h-full">
                  <img
                    className="w-full h-full object-cover"
                    src={`${product.image}`}
                    alt={`${product.title}`}
                  />
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>

      <section className="flex items-center justify-center bg-blue-baybow w-full h-auto px-[1.25rem] py-section-small md:py-section md:px-[5.5em]">
        <div className="flex flex-col items-center justify-center md:w-[85%] h-auto">
          <div className="top w-full md:w-[75%]">
            <h2 className="text-[1.75rem] md:text-[50px] text-center font-[500] text-white">
              N°1 en Afrique en matière d'installation d'ATMs
            </h2>
          </div>

          {/* <ul className="w-full h-auto md:h-[125px] gap-6 md:gap-10 flex-col md:flex-row flex items-center justify-center">
            <li className="flex flex-col items-center justify-center h-full">
              <p className="text-white text-[3rem] font-[600]">5000</p>
              <p className="text-[16px] text-[#c4c4c4] font-[400]">
                Gabs en maintenance
              </p>
            </li>
            <li className="flex flex-col items-center justify-center h-full">
              <p className="text-white text-[3rem] font-[600]">10</p>
              <p className="text-[16px] text-[#c4c4c4] font-[400]">Pays</p>
            </li>
          </ul> */}
        </div>
      </section>

      <div className="bg-white grid w-full lg:w-[85%]">
        <div className="img place-self-center w-[100%] overflow-x-hidden lg:w-full z-10 flex items-start lg:items-center lg:justify-center sticky mt-[15%] lg:mt-0 top-[60px] lg:top-0 left-0 h-[100vh]">
          <div className="absolute shadow-container left-0 flex items-center justify-center w-full lg:w-[350px] h-[28vh] lg:h-[450px] translate-x-[7.5%] lg:translate-x-0">
            <img
              className="w-full h-full object-cover"
              src={
                currentSectionIndex === -1
                  ? images[0]
                  : images[currentSectionIndex]
              }
              alt=""
            />
          </div>
        </div>

        <div className="mt-[-100vh] place-self-center w-[85%] lg:w-full">
          <section className="section-p-sticky w-full lg:h-screen flex items-start lg:items-center justify-end">
            <div className="w-full lg:w-[45%] mt-[calc(28vh+65px)] lg:mt-0 flex flex-col items-start justify-center">
              <h3 className="text-[30px] font-[500] text-black mb-3">
                Baybow: votre partenaire de choix pour le Self-Service Banking
              </h3>
              <p className="text-[16px] text-gray-500">
                Filiale technologique du groupe Burval, Baybow est experte dans
                les solutions de libre-services bancaires en partenariat avec le
                géant mondial GRG Banking.
              </p>
            </div>
          </section>
          <section className="section-p-sticky w-full h-screen flex items-start lg:items-center justify-end">
            <div className="w-full lg:w-[45%] mt-[calc(28vh+65px)] lg:mt-0 flex flex-col items-start justify-center">
              <h3 className="text-[30px] font-[500] text-black mb-3">
                Nos valeurs, la personnalisation et l'excellence
              </h3>
              <p className="text-[16px] text-gray-500">
                Nous ambitionnons d'être la référence pour les solutions de
                banque en libre-service en Afrique. Nous nous engageons à
                innover constamment, en développant des produits et services qui
                répondent aux besoins évolutifs de nos clients. Chaque solution
                est personnalisée pour s'adapter parfaitement à vos exigences.
              </p>
            </div>
          </section>
          <section className="section-p-sticky w-full min-h-screen flex items-start lg:items-center justify-end">
            <div className="w-full lg:w-[45%] mt-[calc(28vh+65px)] lg:mt-0 flex flex-col items-start justify-center">
              <h3 className="text-[30px] font-[500] text-black mb-3">
                Notre expertise au service de vos clients
              </h3>
              <p className="text-[16px] text-gray-500">
                Chez Baybow, nous mettons notre expérience et notre savoir-faire
                à votre disposition. Notre équipe de professionnels dédiés est
                là pour vous accompagner, de l'installation à la maintenance en
                passant par le financement de vos équipements Self-Service
                Banking. Ensemble, nous créerons une meilleure expérience pour
                vos clients.
              </p>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Presentation;
