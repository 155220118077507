import React, { useEffect } from "react";
import "../index.css";
import Tools from "../assets/lotties/maintenance1.json";
import Folder from "../assets/lotties/folder.json";
import Installation from "../assets/lotties/installation.json";
import Lottie from "lottie-react";
import BayBowLogo from "../assets/images/logos/baybow.png";
import TopBar from "../components/TopBar";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import Chevron from "../components/Chevron";

function Services() {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollto");

    if (scrollTo) {
      const element = document.getElementById(scrollTo);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - 60,
          behavior: "smooth",
        });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <TopBar />
      <Chevron />

      <section
        id="top"
        className="relative bg-white z-10 overlay flex flex-col items-center justify-center w-full min-h-screen bg-transparent px-[1.25rem] py-section-small md:py-section md:px-[5.5em]"
      >
        <div className="absolute z-10 bg-[#0000007e] top-0 left-0 w-full h-full"></div>
        <div className="absolute top-0 left-0 min-w-full h-full z-[-1]">
          <video
            autoPlay
            muted
            loop
            playsInline
            poster="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/miniatures%2Fbaybow_services.png?alt=media&token=d9fcef8d-63f0-482d-a9f5-624857ea32e4"
            className="w-full h-full object-cover"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/videos%2Fbaybow_services.mp4?alt=media&token=e1130149-65df-4311-843c-7d2618164760"
              type="video/mp4"
            />
            Votre navigateur ne prend pas en charge la lecture de vidéos.
          </video>
        </div>

        <div className="absolute top-0 left-0 min-w-full h-full z-[-2]">
          <img
            className="w-full h-full object-cover"
            src="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/miniatures%2Fbaybow_services.png?alt=media&token=d9fcef8d-63f0-482d-a9f5-624857ea32e4"
            alt=""
          />
        </div>
        <div className="z-30 flex flex-col items-center justify-center w-full lg:max-w-[1000px]">
          <h1 className="text-center md:text-pretty font-[500] *:text-white">
            <span className="gradient-white font-[500]">Les services</span>{" "}
            <span className="font-[500]">de demain, à</span>{" "}
            <span className="gradient-white-inversed font-[500]">
              portée de main
            </span>
          </h1>
          <p className="text-white text-base text-center md:w-full mx-auto text-[18px] font-[400] my-[1em]">
            Optimisez vos opérations et sécurisez vos transactions avec nos
            solutions hardware et software.
          </p>
          <div className="flex items-center justify-center mt-[2.5em] gap-[1.5em]">
            <a href="#installation">
              <button className="*:hover:cursor-pointer flex items-center justify-center rounded-full py-[12px] px-[16px] bg-blue-baybow">
                <span className="text-white hover:cursor-pointer mr-[5px] text-[16px] font-[500]">
                  Voir nos services
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="bi bi-currency-dollar text-white fill-white w-[22px] h-[22px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                  />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </section>

      <section
        id="installation"
        className="bg-gray-100 w-full lg:w-full flex flex-col lg:flex-row items-center justify-center lg:justify-between h-auto lg:min-h-screen px-[1.25rem] py-section-small md:py-section md:px-[5.5em]"
      >
        <div className="text w-[90%] lg:max-w-[50%]">
          <h1 className="text-center lg:text-start font-[600] mb-6">
            L'installation de GAB, un savoir-faire unique
          </h1>
          <p className="text-[17px] text-center lg:text-start text-balance text-gray-500">
            Notre équipe de professionnel vous garantit simplicité et qualité.
            Chaque étape est maîtrisée, du câblage à l'intégration logicielle,
            pour assurer performance et sécurité. Grâce à notre réactivité et
            notre flexibilité, nous nous adaptons à tous contextes.
          </p>
        </div>

        <div className="lg:max-w-[50%] lg:min-w-auto h-auto flex items-center justify-center mt-[2.5em] lg:mt-0 lg:justify-end">
          <Lottie
            className="w-[80vw] sm:w-[100%] h-auto"
            animationData={Installation}
            loop={true}
          />
        </div>
      </section>

      <section
        id="maintenance"
        className="w-full lg:w-full flex flex-col lg:flex-row items-center justify-center lg:justify-between h-auto lg:min-h-screen px-[1.25rem] py-section-small lg:py-section lg:px-[5.5em]"
      >
        <div className="text w-[90%] lg:max-w-[50%]">
          <h1 className="text-center lg:text-start font-[600] mb-6">
            La maintenance corrective chez Baybow, c'est tout un art !
          </h1>
          <p className="text-[17px] text-center lg:text-start text-balance text-gray-500">
            La maintenance corrective est notre priorité pour assurer la
            disponibilité et la fiabilité de vos équipements. Nos techniciens
            qualifiés interviennent rapidement pour résoudre tout
            dysfonctionnement. Grâce à notre réactivité et expertise, nous
            garantissons une remise en service rapide.
          </p>
        </div>

        <div className="max-w-[50%] w-[45%] lg:min-w-auto h-auto flex items-center justify-center mt-[2.5em] lg:mt-0 lg:justify-end">
          <Lottie animationData={Tools} loop={true} />
        </div>
      </section>

      <section
        id="advices"
        className="relative w-full h-auto px-[1.25rem] py-section-small md:py-section md:px-[5.5em]"
      >
        <div className="bg-shape absolute z-[-1] top-0 left-0 w-full h-[25vh] md:h-[45vh] lg:h-[65vh]"></div>

        <div className="content flex flex-col items-center justify-start">
          <div className="text text-center w-[90%] lg:max-w-[70%] mb-9">
            <h1 className="mb-2 font-[600]">
              La maintenance évolutive chez Baybow
            </h1>
            <p className="text-[17px] font-[400] text-[#333]">
              Avec notre maintenance évolutive, nous optimisons vos
              installations et accompagnons les mutations de votre activité. Nos
              équipes, disponibles à distance ou sur site, intègrent les
              dernières avancées technologiques pour garantir performance et
              sérénité.
            </p>
          </div>

          <div className="image w-full lg:w-[45%] h-[350px] rounded-[8px] overflow-hidden">
            <img
              className="w-full h-full object-cover"
              src="https://images.pexels.com/photos/7861963/pexels-photo-7861963.jpeg?auto=compress&cs=tinysrgb"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="bg-[#d825400d] w-full h-auto flex items-center justify-center px-[1.25rem] py-section-small md:py-section md:px-[5.5em]">
        <div className="flex flex-col items-center justify-start w-[85%] lg:w-full">
          <h2 className="text-[35px] text-center lg:text-start font-[600] mb-10">
            Pour résumer
          </h2>

          <div className="flex flex-wrap items-center justify-center w-full h-auto gap-10">
            <div className="gap-4 p-5 rounded-[12px] w-full max-w-[375px] lg:w-[31%] lg:min-w-[375px] lg:max-w-[400px] flex flex-col items-center justify-center">
              {/* bg-[#0a4f871f] */}
              <div className="flex items-center justify-center rounded-full w-[95px] lg:w-[120px] h-[95px] lg:h-[120px]">
                <span>
                  {/* <Lottie
                    className="w-[65px] lg:w-[90px] h-[65px] lg:h-[90px]"
                    animationData={Folder}
                    loop={true}
                  /> */}
                  <img
                    className="w-[65px] lg:w-[90px] h-[65px] lg:h-[90px]"
                    src="https://firebasestorage.googleapis.com/v0/b/brocand-test.appspot.com/o/movies%2Fcontribution%20(1).png?alt=media&token=5ca67e5d-8cf1-41c5-83f7-223d95f176bb"
                    alt=""
                  />
                </span>
              </div>
              <span className="text-center text-[18px] font-[600]">
                Mise en service accélérée
              </span>
              <p className="text-[15px] text-[#222] text-center text-pretty">
                Notre expertise de l'installation vous permet de rendre un
                nouvel équipement opérationnel dans les meilleurs délais.
              </p>
            </div>
            <div className="gap-4 p-5 rounded-[12px] w-full max-w-[375px] lg:w-[31%] lg:min-w-[375px] lg:max-w-[400px] flex flex-col items-center justify-center">
              <div className="flex items-center justify-center rounded-full w-[95px] lg:w-[120px] h-[95px] lg:h-[120px]">
                <span>
                  <img
                    className="w-[65px] lg:w-[90px] h-[65px] lg:h-[90px]"
                    src="https://firebasestorage.googleapis.com/v0/b/brocand-test.appspot.com/o/movies%2Fperformance.png?alt=media&token=f084e96d-d428-4cda-b934-ed291b34a5f6"
                    alt=""
                  />
                </span>
              </div>

              <span className="text-center text-[18px] font-[600]">
                Disponibilité et performance optimales
              </span>

              <p className="text-[15px] text-[#222] text-center text-pretty">
                Notre suivi continu empêche les pannes et vous assure un
                fonctionnement fluidifié de vos automates.
              </p>
            </div>
            <div className="gap-4 p-5 rounded-[12px] w-full max-w-[375px] lg:w-[31%] lg:min-w-[375px] lg:max-w-[400px] flex flex-col items-center justify-center">
              <div className="flex items-center justify-center rounded-full w-[95px] lg:w-[120px] h-[95px] lg:h-[120px]">
                <span>
                  <img
                    className="w-[65px] lg:w-[90px] h-[65px] lg:h-[90px]"
                    src="https://firebasestorage.googleapis.com/v0/b/brocand-test.appspot.com/o/movies%2Fidea.png?alt=media&token=93027421-b63b-49d6-8675-b1e64da33cfa"
                    alt=""
                  />
                </span>
              </div>

              <span className="text-center text-[18px] font-[600]">
                Innover en toute sérénité
              </span>

              <p className="text-[15px] text-[#222] text-center text-pretty">
                Nous déployons les dernières évolutions de manière transparente
                pour enrichir votre expérience et celle de vos client.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Services;
