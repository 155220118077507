import React, { useEffect, useRef, useState } from "react";
import "../index.css";
import BayBowLogo from "../assets/images/logos/baybow.png";
import TopBar from "../components/TopBar";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import Chevron from "../components/Chevron";
// import Video2 from "../assets/movies/video2.mp4";

type Product = {
  name: string;
  media: string;
  color: string;
  mediaType: "video" | "image";
  description: string;
  specifications: string[];
};

const products: Product[] = [
  {
    name: "H22V",
    color: "bg-[#fff]",
    description:
      "Des DAB hauts de gamme qui repousse les limites de la performance.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/gabs%2Fbaybow_H22.mp4?alt=media&token=636b50ca-8b4a-4bf9-8975-90270fa7be82",
    mediaType: "video",
    specifications: [
      "Dispense rapide (7 billets/s)",
      "Cassettes haute capacité",
      "Lecteur anti-skimming",
      "Interface de maintenance tactile",
    ],
  },
  {
    name: "H34V",
    color: "bg-[#f1f1f1]",
    description: "Le guichet multifonctions par excellence.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/gabs%2Fbaybow_h34v.jpg?alt=media&token=95b3c09e-da0a-4bdd-9eaf-ecaa5f5694cf",
    mediaType: "video",
    specifications: [
      "Jusqu'à 100 billets distribués / transaction",
      "Capacité Cassette : max. 3,500 notes",
      "Écran tactile 19', résistant au vandalisme",
      "Technologie Anti-skimming & Anti-electromagnetic Interference",
      "EPP certifié PCI",
    ],
  },
  {
    name: "H68V",
    color: "bg-[#f5f5f7]",
    description:
      "La nouvelle génération de cash recycler offrant une capacité, une fiabilité et une flexibilité inégalées.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/gabs%2Fbaybow_H68V.mp4?alt=media&token=2896167e-090d-410d-8389-9bd66bd34fa4",
    mediaType: "video",
    specifications: [
      "Capacité élevée (3,350 billets)",
      "Recyclage automatisé",
      "Reconnaissance MICR (option)",
      "Accès multiples (avant, arrière, côté)",
    ],
  },
  {
    name: "P2600E",
    color: "bg-[#0a4f8726]",
    description: "Un CDM à grande capacité, sécurisé et performant.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/gabs%2Fbaybow_P2600E.mp4?alt=media&token=5b6bf093-ebd6-4d07-9d78-a4eb9f2f3e43",
    mediaType: "video",
    specifications: [
      "Haut débit (720 billets/min)",
      "Grande capacité (30,000 billets)",
      "Interface tactile 15",
      "Sécurité élevée (UL291 niveau 1)",
    ],
  },
  {
    name: "P2600L",
    color: "bg-[#d8254026]",
    description:
      "La solution compacte et sécurisée pour une gestion optimale des dépôts de billets.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/gabs%2Fbaybow_p2600.jpg?alt=media&token=ee745333-f68e-4732-b806-6d0265d6e087",
    mediaType: "video",
    specifications: [
      "Grande capacité (15,000 billets)",
      "Compacité du design",
      "Biométrie en option",
      "Connectivité réseau étendue",
    ],
  },
  {
    name: "P2800NL",
    color: "bg-[#f5f5f700]",
    description:
      "Le tout-en-un pour des dépôts de billets sécurisés et efficaces.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/gabs%2Fbaybow_P2800NL_2.jpg?alt=media&token=af9a35c6-93b0-4638-8eae-f6d2e6efd26d",
    mediaType: "video",
    specifications: [
      "Technologie avancée (auto-audit, traçage billets)",
      "Capacité de dépôt élevée (jusqu'à 2,850 billets)",
      "Vitesse de traitement rapide (10 billets/sec)",
      "Sécurité robuste (Coffre UL291 niveau 1)",
    ],
  },
];

function Products() {
  const location = useLocation();
  const screen = useRef<HTMLDivElement>(null);
  const videoRefs = useRef([] as HTMLDivElement[]);
  const sectionRefs = useRef([] as HTMLDivElement[]);
  const sourcesRefs = useRef([] as HTMLVideoElement[] | HTMLImageElement[]);
  const [paused, setPaused] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollto");

    if (scrollTo) {
      const element = document.getElementById(scrollTo);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - 60,
          behavior: "smooth",
        });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const togglePause = () => {
    const isPlaying: boolean = !paused;

    sourcesRefs.current.forEach((video) => {
      if (video instanceof HTMLVideoElement) {
        const isVideoPlaying: boolean =
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > video.HAVE_CURRENT_DATA;

        if (paused && !isVideoPlaying) {
          video.play();
        } else if (!paused && isVideoPlaying) {
          video.pause();
        }
      }
    });

    setPaused(!paused);
  };

  useEffect(() => {
    const handleScroll = () => {
      sectionRefs.current.forEach((sectionRef, index) => {
        console.log(index);
        if (screen.current) {
          const { bottom } = screen.current.getBoundingClientRect();
          const sectionTop = sectionRef.getBoundingClientRect().top;
          const nextVideo = videoRefs.current[index];

          const height = screen.current.offsetHeight;

          if (sectionTop <= bottom) {
            let difference = bottom - sectionTop;
            let percentage = (difference * 100) / height;
            let origin = percentage / 100;
            let scale = 1 / origin;

            if (origin >= 1) {
              scale = 1;
              origin = 1;
            }

            if (nextVideo) {
              nextVideo.style.transform = `matrix(1, 0, 0, ${origin}, 0, 0)`;
              const firstVideoOfNextVideo = nextVideo.querySelector("video");
              if (firstVideoOfNextVideo) {
                firstVideoOfNextVideo.style.transform = `scaleY(${scale})`;
              }
            }
          } else {
            let origin = 0;
            let scale = 1;

            if (nextVideo) {
              nextVideo.style.transform = `matrix(1, 0, 0, ${origin}, 0, 0)`;
              const firstVideoOfNextVideo = nextVideo.querySelector("video");
              if (firstVideoOfNextVideo) {
                firstVideoOfNextVideo.style.transform = `scaleY(${scale})`;
              }
            }
          }
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="sections">
        <TopBar />
        <Chevron />

        <section
          id="top"
          className="relative bg-white z-10 overlay flex flex-col items-center justify-center w-full min-h-screen bg-transparent px-[1.25rem] py-section-small md:py-section md:px-[5.5em]"
        >
          <div className="absolute z-10 bg-[#0000007e] top-0 left-0 w-full h-full"></div>
          <div className="absolute top-0 left-0 min-w-full h-full z-[-1]">
            <video
              autoPlay
              muted
              loop
              playsInline
              poster="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/miniatures%2Fbaybow_notre_gramme_d_automates.png?alt=media&token=704de55e-0ac3-4a1d-9a74-613f4e8bd724"
              className="w-full h-full object-cover"
            >
              <source
                src="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/videos%2Fbaybow_notre_gamme_d_automates.mp4?alt=media&token=6bdf66c1-1f5a-4be9-9f43-737b79f54f3e"
                type="video/mp4"
              />
              Votre navigateur ne prend pas en charge la lecture de vidéos.
            </video>
          </div>

          <div className="absolute top-0 left-0 min-w-full h-full z-[-2]">
            <img
              className="w-full h-full object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/brocand-test.appspot.com/o/movies%2Fminiatures%2Fatm_min.png?alt=media&token=68358ba9-9206-4130-9833-8960ed1515ac"
              alt=""
            />
          </div>
          <div className="z-30 flex flex-col items-center justify-center w-full lg:max-w-[1000px]">
            <h1 className="text-center md:text-pretty font-[500] *:text-white">
              <span className="font-[500]">Des</span>{" "}
              <span className="gradient-white font-[500]">automates</span>{" "}
              <span className="font-[500]">en phase avec les</span>{" "}
              <span className="gradient-white-inversed font-[500]">
                nouveaux usages
              </span>
            </h1>
            <p className="text-white text-base text-center text-pretty lg:w-[60%] mx-auto text-[18px] font-[400] my-[1em]">
              Notre palette d'équipements self-service banking accompagne votre
              organisation dans son adaptation aux parcours client diversifiés
              et personnalisés.
            </p>
            <div className="btns gap-[1.5em] my-10 flex flex-wrap items-center justify-center w-full px-3 md:px-0">
              <a href="#DAB/GAB" className="*:hover:cursor-pointer">
                <button className="*:hover:cursor-pointer flex items-center justify-center rounded-full py-[12px] px-[16px] bg-transparent">
                  <label className="text-white mr-[5px] text-[16px] font-[500]">
                    Voir les modeles de GAB
                  </label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    className="bi bi-currency-dollar text-white fill-white w-[22px] h-[22px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                    />
                  </svg>
                </button>
              </a>

              <a href="https://baybow.asia/contact-us">
                <button className="*:hover flex items-center justify-center rounded-full py-[12px] px-[16px] bg-blue-baybow">
                  <label className="text-white mr-[5px] text-[16px] font-[500]">
                    Demander un devis
                  </label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 text-white w-[22px] h-[22px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </a>
            </div>
          </div>
        </section>

        <div id="DAB/GAB" className="bg-white">
          <div className="overflow-hidden z-10 flex items-start lg:items-center justify-center pt-[60px] lg:pt-0 h-screen sticky w-[calc(100%-1.75rem)] max-w-[500px] md:max-w-[50vw] top-0 left-[50%] ">
            <div
              ref={screen}
              className="block border-[4.75px] border-[#222] absolute w-full overflow-hidden h-[35vh] sm:h-[250px] md:h-[380px] lg:h-[390px] xl:h-[510px]"
            >
              <button
                onClick={togglePause}
                className="absolute duration-300 z-50 bottom-[20px] right-[20px] flex items-center justify-center w-[40px] h-[40px] rounded-full bg-[#d2d2d7] text-black fill-black"
              >
                <span>
                  {paused ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-[20px] h-[20px]"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={5}
                      stroke="currentColor"
                      className="w-[20px] h-[20px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                      />
                    </svg>
                  )}
                </span>
              </button>
              {products.map((item, index) => (
                <div
                  ref={(ref) => {
                    if (ref) {
                      videoRefs.current[index] = ref;
                    }
                  }}
                  className="absolute overflow-hidden block origin-bottom top-0 w-full h-full"
                >
                  <div className="absolute z-[-1] w-full h-full animate-pulse gradient-swiper"></div>
                  {item.mediaType === "image" ? (
                    <img
                      ref={(ref) => {
                        if (ref) {
                          sourcesRefs.current[index] = ref;
                        }
                      }}
                      src={item.media}
                      alt={item.media}
                      className={`${item.name} w-full max-w-full h-full max-h-full object-cover origin-bottom z-[1]`}
                    />
                  ) : (
                    <video
                      ref={(ref) => {
                        if (ref) {
                          sourcesRefs.current[index] = ref;
                        }
                      }}
                      autoPlay
                      muted
                      loop
                      playsInline
                      poster={item.media}
                      className={`${item.name} w-full max-w-full h-full max-h-full object-cover origin-bottom z-[1]`}
                    >
                      <source src={item.media} type="video/mp4" />
                      Votre navigateur ne prend pas en charge la lecture de
                      vidéos.
                    </video>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="presentation mt-[-100vh] bg-transparent">
            {products.map((item, index) => (
              <section
                id={item.name}
                key={index}
                ref={(ref) =>
                  (sectionRefs.current[index] = ref as HTMLDivElement)
                }
                className={`w-full border-[3px] border-transparent h-auto sm:h-screen overflow-hidden pl-7 md:pl-10 lg:pl-14 ${item.color}`}
              >
                <div
                  className={`flex flex-col items-start justify-start mt-[calc(4em+35vh)] sm:mt-[calc(4em+250px)] md:mt-[calc(50vh-265px)] max-w-[85vw] md:max-w-[40vw] min-h-auto mb-[100px] md:mb-0 md:h-screen top-0`}
                >
                  <div className="title w-full">
                    <h2 className="text-center lg:text-start text-[50px] font-[500]">
                      <span className="gradient-white">{item.name}</span>
                    </h2>
                  </div>

                  <div className="description text-center lg:text-start text-[17px] my-5 text-[#959495]">
                    <p>{item.description}</p>
                  </div>

                  <div className="specs mt-[10px]">
                    <label className="font-[600] text-[18px]">
                      Caractéristiques du modele
                    </label>
                    <ul className="list-disc list-inside mt-4">
                      {item.specifications.map((spec, index) => (
                        <li
                          key={index}
                          className="text-[#222] text-[16px] font-[500] mb-[8px]"
                        >
                          {spec}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="btn"></div>
                </div>
              </section>
            ))}
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Products;
