import React, { ChangeEvent, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "../index.css";
import TopBar from "../components/TopBar";
import { useLocation } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { sendMail } from "../firebase/authentication/email";

const key: string = "6LdshOYpAAAAAJ3iuO7cAt-RHZmWg-3NYQ_3bMcJ";

export interface User {
  nom: string;
  prenom: string;
  email: string;
  numeroDeTelephone: string;
  message: string;
}

const firstAndLastNameRegex: RegExp = /^[a-z ,.'-]+$/i;
const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex: RegExp = /^\+\d+$/;

function formatLabel(label: string): string {
  const formattedLabel = label.replace(/([A-Z])/g, " $1");
  return (
    formattedLabel.charAt(0).toUpperCase() +
    formattedLabel.slice(1).toLowerCase()
  );
}

function ContactUs() {
  const location = useLocation();

  const [user, setUser] = useState<User>({
    nom: "",
    prenom: "",
    email: "",
    numeroDeTelephone: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recaptcha, setRecaptcha] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollto");

    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollto");

    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const activeToast = (message: string, success: boolean) => {
    toast.promise(
      () =>
        new Promise<void>((resolve, reject) => {
          setTimeout(() => {
            if (success) {
              resolve();
            } else {
              reject(new Error("L'opération a échouée."));
            }
          }, 2000);
        }),
      {
        loading: "Chargement...",
        success: () => {
          setTimeout(() => {}, 2000);
          return message;
        },
        error: () => {
          return message;
        },
      }
    );
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const submit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!firstAndLastNameRegex.test(user.nom) || user.nom.length === 0) {
      toast.error("Le nom n'est pas valide.");
      return;
    }

    if (!firstAndLastNameRegex.test(user.prenom)) {
      toast.error("Le prenom n'est pas valide.");
      return;
    }

    if (!emailRegex.test(user.email)) {
      toast.error("L'email n'est pas valide.");
      return;
    }

    if (!phoneRegex.test(user.numeroDeTelephone)) {
      toast.error("Le numero de telephone n'est pas valide.");
      return;
    }

    if (!user.message || user.message.trim().length === 0) {
      toast.error("Le message ne peut pas être vide.");
      return;
    }

    if (!recaptcha || recaptcha?.length === 0) {
      toast.error("Veuillez valider le reCAPTCHA.");
      return;
    }

    setIsLoading(true);
    sendMail(user, recaptcha, setRecaptcha, activeToast, setIsLoading);
  };

  const handleCaptchaChange = (value: string | null) => {
    setRecaptcha(value);
  };

  return (
    <div>
      <TopBar />
      <Toaster richColors position="top-center" />

      <section className="relative h-auto bg-grid-[#0A4F87]/[0.2] flex flex-col items-center justify-start p-4 md:p-6 py-[3em]">
        <div className="absolute z-[1] pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_-60%,black)]"></div>
        <div className="section-header z-10 w-full flex flex-col items-center justify-center mt-5 mb-12">
          <h2 className="text-[32px] md:text-[40px] text-center font-[600] gradient">
            Prenez contact, ouvrons le dialogue
          </h2>
          <p className="text-[14px] md:text-[16px] text-center max-w-[90%] md:max-w-[60%] text-gray-500 font-[400] mt-3">
            Notre équipe est à votre écoute pour comprendre vos enjeux et
            objectifs, puis vous proposer une solution sur-mesure adaptée à vos
            contraintes. N'hésitez pas à nous contacter pour toute demande
            d'information ou de devis.
          </p>
        </div>

        <div className="section-content z-10 flex flex-col md:flex-row items-start justify-center w-auto mx-auto md:w-full h-auto mt-8">
          <table className="left flex flex-col items-center justify-center w-full md:w-[50%] h-auto">
            <tbody>
              <tr className="flex flex-col md:flex-row">
                <td className="p-2 max-w-1/2">
                  <Input
                    name="nom"
                    placeholder="Votre nom"
                    type="text"
                    value={user.nom}
                    onChangeFunc={handleChange}
                  />
                </td>
                <td className="p-2 w-full">
                  <Input
                    name="prenom"
                    placeholder="Votre prénom"
                    type="text"
                    value={user.prenom}
                    onChangeFunc={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="p-2">
                  <Input
                    name="email"
                    placeholder="votre@societe.com"
                    type="email"
                    value={user.email}
                    onChangeFunc={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="p-2">
                  <Input
                    name="numeroDeTelephone"
                    placeholder="Indicatif + Téléphone"
                    type="text"
                    value={user.numeroDeTelephone}
                    onChangeFunc={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="p-2">
                  <TextArea
                    name="message"
                    placeholder="Laissez-nous un message !"
                    value={user.message}
                    onChangeFunc={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className="recaptcha-view">
                    <ReCAPTCHA sitekey={key} onChange={handleCaptchaChange} />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="p-2">
                  <button
                    type="submit"
                    onClick={submit}
                    className="w-full flex items-center justify-center bg-black cursor-pointer rounded-[10px] text-white h-[45px]"
                  >
                    {isLoading && (
                      <span className="text-[15px] text-ellipsis overflow-hidden font-[400] text-white leading-[19px] m-0">
                        Loading...
                      </span>
                    )}
                    {!isLoading && (
                      <span className="text-[15px] text-ellipsis overflow-hidden font-[400] text-white leading-[19px] m-0">
                        Envoyer votre demande
                      </span>
                    )}
                    {!isLoading && (
                      <span className="ml-3 rotate-[315deg]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-[22px] h-[22px] text-white fill-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                          />
                        </svg>
                      </span>
                    )}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="right p-2 flex flex-col items-start justify-center w-auto md:w-[40%] mt-8 md:mt-0">
            <div>
              <h3 className="text-[16px] md:text-[17px] mb-1 font-[600]">
                Chattez avec nous
              </h3>
              {/* <p className="text-[14px] md:text-[15px] text-start font-[400] text-gray-500">
                Envoyez un mail à notre équipe sympathique pour toute
                assistance.
              </p> */}
            </div>

            <div className="links mb-9">
              {/* <button className="flex items-center justify-start mb-3 w-full">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-[20px] h-[20px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                    />
                  </svg>
                </span>
                <span className="font-[500] underline text-start underline-offset-2">
                  Start a live chat
                </span>
              </button> */}
              <a href="mailto:info@baybow.asia">
                <button className="flex items-center justify-start mb-3 w-full">
                  <span className="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-[20px] h-[20px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                  </span>

                  <span className="font-[500] underline text-start underline-offset-2">
                    Envoyez nous un mail
                  </span>
                </button>
              </a>
              {/* <button className="flex items-center justify-start mb-3 w-full">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    strokeWidth={2}
                    height="16"
                    fill="currentColor"
                    className="w-[20px] h-[20px]"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                  </svg>
                </span>
                <span className="font-[500] underline text-start underline-offset-2">
                  Envoyez nous un message sur LinkedIn
                </span>
              </button> */}
            </div>

            <div className="mb-9">
              <h3 className="text-[16px] md:text-[17px] mb-1 font-[600]">
                Appelez-nous !
              </h3>
              {/* <p className="text-[14px] md:text-[15px] text-start font-[400] text-gray-500 my-2">
                Appelez notre équipe sympathique et disponible pour vous aider.
              </p> */}
              <a href="tel:+2250707851722">
                <button className="flex items-center justify-start mb-3 w-full">
                  <span className="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-[20px] h-[20px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                      />
                    </svg>
                  </span>
                  <span className="font-[500] underline text-start underline-offset-2">
                    +2250707851722
                  </span>
                </button>
              </a>
            </div>

            <div className="mb-4">
              <h3 className="text-[16px] md:text-[17px] mb-1 font-[600]">
                Visitez-nous !
              </h3>
              {/* <p className="text-[14px] md:text-[15px] text-start font-[400] text-gray-500 my-2">
                Venez rencontrer notre équipe sympathique en personne dans nos
                locaux.
              </p> */}
              <button className="flex items-center justify-start mb-3 w-full">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-[20px] h-[20px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                    />
                  </svg>
                </span>
                <span className="font-[500] underline text-start underline-offset-2">
                  7X8W+CQV, Rue des Textiles, Abidjan, Côte d'Ivoire
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const Input = ({
  name,
  type,
  value,
  onChangeFunc,
  placeholder,
}: {
  name: string;
  type: string;
  value: string;
  placeholder: string;
  onChangeFunc: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) => {
  return (
    <div className="flex mb-[1.2em] flex-col items-start justify-start w-full">
      <label className="text-[16px] text-black font-[500]">
        {formatLabel(name)}
      </label>
      <div className="overflow-hidden w-full bg-white mt-[5px] border-[1.65px] border-[#d5d5d5] rounded-[10.5px]">
        <input
          id="floatingInput"
          required
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChangeFunc}
          className="w-full border-none outline-none"
        />
      </div>
      {name === "numeroDeTelephone" && (
        <small className="text-red-500 text-[13px] mt-3 lg:max-w-[70%]">
          Le numéro de téléphone doit contenir l'indicatif téléphonique avec le
          +. Ex. +22660606060
        </small>
      )}
    </div>
  );
};

const TextArea = ({
  name,
  placeholder,
  value,
  onChangeFunc,
}: {
  name: string;
  placeholder: string;
  value: string;
  onChangeFunc: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) => {
  return (
    <div className="flex mb-[1.2em] flex-col items-start justify-start w-full">
      <label className="text-[16px] text-black font-[400]">
        {formatLabel(name)}
      </label>
      <div className="overflow-hidden w-full bg-white mt-[5px] border-[1.65px] border-[#d5d5d5] rounded-[10.5px]">
        <textarea
          className="w-full border-none outline-none"
          required
          value={value}
          name={name}
          onChange={onChangeFunc}
          placeholder={placeholder}
          id="floatingTextarea"
        ></textarea>
      </div>
    </div>
  );
};

export default ContactUs;
