import { cn } from "../utils/cn";
import React from "react";
import { BentoGrid, BentoGridItem } from "./ui/bento-grid";
import {
  IconArrowWaveRightUp,
  IconBoxAlignRightFilled,
  IconBoxAlignTopLeft,
  IconClipboardCopy,
  IconFileBroken,
  IconSignature,
  IconTableColumn,
} from "@tabler/icons-react";
import { New } from "../sections/News";

export function BentoGridApp({ news }: { news: New[] }) {
  return (
    <BentoGrid className="min-w-full mx-auto">
      {news.length === 0 && (
        <span className="text-gray-500 text-[15px]">Aucune actualitée.</span>
      )}
      {news.map((item, i) => (
        <BentoGridItem
          key={i}
          item={item}
          icon={<IconSignature className="h-4 w-4 text-neutral-500" />}
          className={`${
            i === 3 || i === 6 ? "md:col-span-2" : ""
          } border-shadow`}
        />
      ))}
    </BentoGrid>
  );
}
const Skeleton = () => (
  <div className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl bg-gradient-to-br from-neutral-200 dark:from-neutral-900 dark:to-neutral-800 to-neutral-100"></div>
);
