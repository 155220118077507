import React, { useEffect } from "react";
import Lottie from "lottie-react";
import Atm from "../assets/lotties/atm.json";
import BayBowLogo from "../assets/images/logos/baybow.png";
import Folder from "../assets/lotties/folder.json";
import Video1 from "../assets/movies/advces.mp4";
import "../index.css";
import TopBar from "../components/TopBar";
import Footer from "../components/Footer";
import Paiment from "../assets/images/gifs/paiement.gif";
import Rentability from "../assets/images/gifs/rentabilite.gif";
import Entretien from "../assets/images/gifs/entretien.gif";
import { useLocation } from "react-router-dom";

const comparison: string[] = [
  "Investissement initial faible",
  "Coûts de maintenance stables",
  "Agilité de renouvellement",
  "Facilité de financement",
  "Gestion de la logistique",
  "Accès immédiat aux innovations",
  "Budget d'investissement lissé",
];

function OL() {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollto");

    if (scrollTo) {
      const element = document.getElementById(scrollTo);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - 60,
          behavior: "smooth",
        });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <TopBar />
      <section
        id="outsourcing"
        className="relative bg-white z-10 overlay flex flex-col items-center justify-start w-full min-h-screen bg-transparent px-[1.25rem] py-section-small md:py-section md:px-[5.5em]"
      >
        <div className="absolute z-10 bg-[#0000007e] top-0 left-0 w-full h-full"></div>
        <div className="absolute top-0 left-0 min-w-full h-full z-[-1]">
          <video
            autoPlay
            muted
            loop
            playsInline
            poster="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/miniatures%2Fbaybow_outsourcing_and_leasing.png?alt=media&token=d3eea995-0d40-48f0-96bf-c8c120a6020d"
            className="w-full h-full object-cover"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/videos%2Fbaybow_outsourcing_and_leasing.mp4?alt=media&token=eaf4d525-9427-4129-8a97-540939a1f06d"
              type="video/mp4"
            />
            Votre navigateur ne prend pas en charge la lecture de vidéos.
          </video>
        </div>
        <div className="absolute top-0 left-0 min-w-full h-full z-[-2]">
          <img
            className="w-full h-full object-cover"
            src="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/miniatures%2Fbaybow_outsourcing_and_leasing.png?alt=media&token=d3eea995-0d40-48f0-96bf-c8c120a6020d"
            alt=""
          />
        </div>
        <div className="z-30 mt-[4.5rem] flex flex-col items-center justify-center lg:max-w-[1000px]">
          <h1 className="text-center md:text-pretty font-[500] *:text-white">
            <span className="gradient-white font-[500]">Concentrer vous</span>{" "}
            <span className="font-[500]">sur l'essentiel</span>
            <span className="font-[500]">, nous nous occupons</span>{" "}
            <span className="gradient-white-inversed font-[500]">du reste</span>
          </h1>
          <p className="text-white text-base text-center md:w-full mx-auto text-[18px] font-[400] my-[1em]">
            Notre gamme de prestations sur mesure vous permet de concentrer vos
            efforts sur votre métier cœur tout en bénéficiant des dernières
            technologies de manière souple et sécurisée
          </p>
          <div className="btns gap-[1.5em] my-10 flex flex-wrap items-center justify-center w-full px-3 md:px-0">
            <a href="#outsourcing1" className="*:hover:cursor-pointer">
              <button className="*:hover flex items-center justify-center rounded-full py-[12px] px-[16px] bg-blue-baybow">
                <label className="cursor-pointer text-white mr-[5px] text-[16px] font-[500]">
                  Explorez nos solutions
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="bi bi-currency-dollar text-white fill-white w-[22px] h-[22px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                  />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </section>
      {/*  */}
      <section
        id="outsourcing1"
        className="w-full md:w-full flex flex-col md:flex-row items-center justify-center md:justify-between h-auto lg:min-h-screen px-[1.25rem] py-section-small md:py-section md:px-[5.5em]"
      >
        <div className="text w-[90%] md:max-w-[50%]">
          <h1 className="text-center md:text-start font-[600] mb-6">
            Libérez-vous pour une <span className="word">agilité</span> maximale
          </h1>
          <p className="text-[17px] text-center md:text-start text-balance text-gray-500">
            Notre outsourcing complet vous fait gagner un temps précieux pour
            vous concentrer sur votre cœur de métier. La gestion experte de
            notre parc d'automates vous assure performance, disponibilité et
            sérénité dans l'évolution de vos activités.
          </p>
        </div>

        <div className="max-w-[50%] lg:min-w-auto h-auto flex items-center justify-center mt-[2.5em] md:mt-0 md:justify-end">
          <Lottie
            className="w-[65vw] sm:w-[55%]"
            animationData={Atm}
            loop={true}
          />
        </div>
      </section>
      {/*  */}
      <section className="bg-[#f5f5f7] w-full h-auto flex items-center justify-center px-[1.25rem] py-section-small md:py-section md:px-[5.5em]">
        <div className="flex flex-col items-center justify-start w-[85%] lg:w-full">
          <h2 className="text-[35px] text-center lg:text-start font-[600] mb-10">
            Maitrisez vos coûts de renouvellement
          </h2>

          <div className="flex flex-wrap items-center justify-center w-full h-auto gap-10">
            <div className="gap-4 p-5 rounded-[12px] border-shadow-box bg-white w-full max-w-[375px] lg:w-[31%] lg:min-w-[375px] lg:max-w-[400px] flex flex-col items-center justify-center">
              <div className="flex items-center justify-center bg-[#0a4f871f] rounded-full w-[95px] lg:w-[120px] h-[95px] lg:h-[120px]">
                <span>
                  {/* <Lottie
                    className="w-[65px] lg:w-[90px] h-[65px] lg:h-[90px]"
                    animationData={Folder}
                    loop={true}
                  /> */}
                  <img src={Paiment} alt="" />
                </span>
              </div>

              <span className="text-center text-[18px] font-[600]">
                Paiements lissés dans le temps
              </span>

              <p className="text-[15px] text-[#222] text-center text-pretty">
                Notre leasing vous évite les décaissements importants en vous
                offrant une prévisibilité budgétaire pérenne.
              </p>
            </div>
            <div className="gap-4 p-5 rounded-[12px] border-shadow-box bg-white w-full max-w-[375px] lg:w-[31%] lg:min-w-[375px] lg:max-w-[400px] flex flex-col items-center justify-center">
              <div className="flex items-center justify-center bg-[#0a4f871f] rounded-full w-[95px] lg:w-[120px] h-[95px] lg:h-[120px]">
                <span>
                  {/* <Lottie
                    className="w-[65px] lg:w-[90px] h-[65px] lg:h-[90px]"
                    animationData={Folder}
                    loop={true}
                  /> */}
                  <img src={Rentability} alt="" />
                </span>
              </div>

              <span className="text-center text-[18px] font-[600]">
                Agilité et réactivité accrues
              </span>

              <p className="text-[15px] text-[#222] text-center text-pretty">
                Notre parc matériel toujours à jour vous permet de tirer
                immédiatement parti des évolutions pour enrichir l'expérience
                client.
              </p>
            </div>
            <div className="gap-4 p-5 rounded-[12px] border-shadow-box bg-white w-full max-w-[375px] lg:w-[31%] lg:min-w-[375px] lg:max-w-[400px] flex flex-col items-center justify-center">
              <div className="flex items-center justify-center bg-[#0a4f871f] rounded-full w-[95px] lg:w-[120px] h-[95px] lg:h-[120px]">
                <span>
                  {/* <Lottie
                    className="w-[65px] lg:w-[90px] h-[65px] lg:h-[90px]"
                    animationData={Folder}
                    loop={true}
                  /> */}
                  <img src={Entretien} alt="" />
                </span>
              </div>

              <span className="text-center text-[18px] font-[600]">
                Entretien, maintenance, assurances
              </span>

              <p className="text-[15px] text-[#222] text-center text-pretty">
                Nous nous chargeons de la gestion complète du cycle de vie de
                vos automates pour une exploitation sereine.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section
        id="leasing"
        className="py-section bg-white w-full h-auto flex items-center justify-center"
      >
        <div className="flex flex-col items-start justify-start w-[85%]">
          <h2 className="text-[35px] font-[600] mb-10">
            Nos offres de leasing
          </h2>

          <div className="flex flex-col items-start justify-start w-full h-auto">
            <div className="row *:text-[22px] *:font-[500] w-full flex items-center py-5">
              <div hidden>
                <p>Les avantages de l'offre outsourcing/leasing</p>
              </div>
              <div className="ml-[66.6666666667%] basis-[16.6666666667%] max-w-[16.6666666667%]">
                <p className="text-[16px]">Sans notre offre</p>
              </div>
              <div className="basis-[16.6666666667%] max-w-[16.6666666667%]">
                <p className="text-[16px]">Avec notre offre</p>
              </div>
            </div>

            {/* <div className="row *:text-start w-full flex items-center py-5 border-b-[1px] border-b-[#0000051a]">
              <div className="basis-[66.6666666667%] max-w-[66.6666666667%]">
                <p className="text-[#222] text-[16px]">Critere 1</p>
              </div>
              <div className="basis-[16.6666666667%] max-w-[16.6666666667%]">
                <span className="*:fill-red-baybow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                  </svg>
                </span>
              </div>
              <div className="basis-[16.6666666667%] max-w-[16.6666666667%]"></div>
            </div> */}

            {comparison.map((obj, index) => (
              <div
                key={index}
                className="row *:text-start w-full flex items-center py-5 border-b-[1px] border-b-[#0000051a]"
              >
                <div className="basis-[66.6666666667%] max-w-[66.6666666667%]">
                  <p className="text-[#222] text-[16px]">{obj}</p>
                </div>
                <div className="basis-[16.6666666667%] max-w-[16.6666666667%]">
                  <span className="*:text-red-baybow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2.22}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </div>
                <div className="basis-[16.6666666667%] max-w-[16.6666666667%]">
                  <span className="*:text-blue-baybow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2.25}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default OL;
