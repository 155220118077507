import React, { useEffect, useState, useRef } from "react";
import "../index.css";
import BBSLogo from "../assets/images/logos/baybow.png";
import { useNavigate } from "react-router-dom";

interface Option {
  title: string;
  route: string;
  content: { name: string; scrollTo: string }[];
}

interface NavItem {
  name: string;
  title: string;
  route: string;
  options: Option[];
}

const navbar: NavItem[] = [
  {
    name: "Accueil",
    title: "Accueil",
    options: [],
    route: "",
  },
  {
    name: "Produits & Services",
    title: "Produits & Services",
    route: "",
    options: [
      {
        title: "Nos services",
        content: [
          { name: "Installation", scrollTo: "installation" },
          {
            name: "Maintenance Préventive/Curative",
            scrollTo: "maintenance",
          },
          {
            name: "Accompagnement & Conseil",
            scrollTo: "advices",
          },
          {
            name: "Voir tous nos services",
            scrollTo: "top",
          },
        ],
        route: "services",
      },
      {
        title: "Notre Gamme d'automates",
        content: [
          { name: "DAB/GAB", scrollTo: "DAB/GAB" },
          { name: "CDM", scrollTo: "P2600E" },
          {
            name: "Voir toute notre gamme",
            scrollTo: "top",
          },
        ],
        route: "gabs",
      },
      {
        title: "Notre suite logicielle",
        content: [
          {
            name: "Notre suite logicielle",
            scrollTo: "Notre suite logicielle",
          },
        ],
        route: "software",
      },
      {
        title: "Outsourcing, Leasing",
        content: [{ name: "Outsourcing, Leasing", scrollTo: "outsourcing" }],
        route: "outsourcing-and-leasing",
      },
    ],
  },

  {
    name: "Actualités",
    title: "Actualités",
    options: [],
    route: "news",
  },
  {
    name: "A propos",
    title: "A propos",
    route: "about-us",

    options: [
      {
        title: "Qui sommes-nous",
        content: [
          {
            name: "Qui sommes-nous",
            scrollTo: "who-are-we",
          },
        ],
        route: "about-us",
      },
      {
        title: "Notre presence",
        content: [
          {
            name: "Notre presence",
            scrollTo: "our-presence",
          },
        ],
        route: "about-us?scrollto=our-presence",
      },
      {
        title: "Notre équipe",
        content: [
          {
            name: "Notre équipe",
            scrollTo: "our-team",
          },
        ],
        route: "about-us?scrollto=our-team",
      },
      // {
      //   title: "FAQ",
      //   content: [
      //     {
      //       name: "FAQ",
      //       scrollTo: "faq",
      //     },
      //   ],
      //   route: "faq",
      // },
    ],
  },
  {
    name: "Nous contacter",
    title: "Nous contacter",
    route: "contact-us",
    options: [],
  },
];

function Navbar() {
  const navigate = useNavigate();
  const divRef = useRef<HTMLUListElement>(null);

  const [sideBar, setSideBar] = useState<boolean>(false);
  const [firstNav, setFirstNav] = useState<{
    selected: boolean;
    options: Option[] | null;
  }>({
    selected: false,
    options: null,
  });
  const [secondNav, setSecondNav] = useState<{
    route: string;
    selected: boolean;
    options: { name: string; scrollTo: string }[] | null;
  }>({
    route: "",
    selected: false,
    options: null,
  });
  const [currentNavItem, setCurrentNavItem] = useState<NavItem | null>(null);
  const [currentOption, setCurrentOption] = useState<Option | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        currentNavItem &&
        divRef.current &&
        !divRef.current.contains(event.target as Node)
      ) {
        setCurrentNavItem(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [currentNavItem]);

  const goTo = (route: string, scrollTo?: string) => {
    setSideBar(false);
    setFirstNav({ selected: false, options: [] });
    setSecondNav({ selected: false, options: [], route: "" });

    if (scrollTo) {
      setTimeout(() => {
        navigate(`/${route}?scrollto=${scrollTo}`);
      }, 600);
    } else {
      setTimeout(() => {
        navigate(`/${route}`);
      }, 600);
    }
  };

  const handleNavItemMouseEnter = (navItem: NavItem) => {
    setCurrentNavItem(null);
    setCurrentOption(null);
    if (navItem.options.length > 0) {
      setCurrentOption(navItem.options[0]);
      setCurrentNavItem(navItem);
    }
  };

  const handleOptionClick = (option: Option) => {
    if (option.content.length === 1) {
      setCurrentNavItem(null);

      setTimeout(() => {
        goTo(option.route);
      }, 300);
      return;
    }
    setCurrentOption(option);
  };

  const handleOptionMouseLeave = () => {
    setCurrentNavItem(null);
  };

  return (
    <header className="sticky backdrop-blur-sm backdrop-filter bg-[#fafafccc] flex items-center justify-center max-w-full z-[999] top-0 h-[60px] lg:h-[66px] w-full">
      <hr className="absolute header-bottom bottom-0 w-full h-[2px] -translate-x-1/2 border-0 opacity-10 left-1/2"></hr>

      <div className="flex items-center justify-between w-[85%] md:w-[80%] h-full">
        {(firstNav.selected || secondNav.selected) && (
          <button
            onClick={(e) => {
              if (secondNav.selected) {
                setSecondNav({
                  selected: false,
                  options: secondNav.options,
                  route: "",
                });
              } else if (firstNav.selected) {
                setFirstNav({
                  selected: false,
                  options: firstNav.options,
                });
              }
            }}
          >
            <span className="text-[#333]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-[22px] h-[22px]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
            </span>
          </button>
        )}
        {!firstNav.selected && (
          <div className="logo flex items-center justify-center *:hover:cursor-pointer w-[35%] max-w-[125px] lg:max-w-[150px] max-h-full overflow-hidden">
            <a href="https://baybow.asia" rel="noreferrer">
              <img
                className="w-full h-full object-cover"
                src={BBSLogo}
                alt=""
              />
            </a>
          </div>
        )}

        <nav
          className={`fixed flex flex-col items-center justify-start lg:hidden ${
            sideBar ? "bg-visible-black" : "bg-transparent-black"
          } lg:relative top-[60px] lg:top-0 left-0 w-full lg:w-auto h-screen lg:h-auto bg-[#d3d3d3] lg:bg-transparent shadow-lg lg:shadow-none lg:flex`}
        >
          <ul
            className={`absolute text-white ${
              firstNav.selected ? "animation-fade-off" : "animation-fade-on"
            } *:text-[#333] *:font-[500] w-[85%] md:w-[80%] py-[10px] lg:p-0 flex-col gap-6 pt-[1.15em] lg:pt-0 flex items-start lg:items-center justify-start list-none text-[30px] lg:text-[17px]`}
          >
            {navbar.map((navItem, index) => (
              <li
                key={index}
                onClick={(e) => {
                  navItem.options.length !== 0 &&
                    setFirstNav({
                      selected: true,
                      options: navItem.options,
                    });

                  navItem.options.length === 0 && goTo(navItem.route);
                }}
                className={`hover:text-[#333] flex items-center justify-center ${
                  !sideBar && "animate-fade-off hidden"
                } lg:flex lg:visible animate-fade duration-200 hover:cursor-pointer`}
              >
                <span className="text-[25px] md:text-[30px] font-[600]">
                  {navItem.title}
                </span>
                <div
                  className={`ml-[8px] lg:hidden flex items-center justify-center`}
                >
                  <svg
                    strokeWidth={3}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="bi bi-arrow-right w-[22px] h-[22px]"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </div>
              </li>
            ))}
          </ul>

          <ul
            className={`absolute text-white ${
              firstNav.selected ? "animation-fade-on" : "animation-fade-off"
            } ${secondNav.selected ? "animation-fade-off" : ""}
          *:text-[#333] *:font-[500] w-[85%] md:w-[80%] py-[10px] lg:p-0 flex-col gap-7 pt-[1.15em] lg:pt-0 flex items-start lg:items-center justify-start list-none text-[30px] lg:text-[17px]`}
          >
            {firstNav.options &&
              firstNav.options.map((navItem, index) => (
                <li
                  key={index}
                  onClick={(e) => {
                    navItem.content.length !== 1 &&
                      setSecondNav({
                        selected: true,
                        options: navItem.content,
                        route: navItem.route,
                      });

                    navItem.content.length === 1 &&
                      goTo(navItem.route, navItem.content[0].scrollTo);
                  }}
                  className={`hover:text-[#333] flex items-center justify-center ${
                    !sideBar && "animate-fade-off hidden"
                  }  lg:flex lg:visible duration-200 hover:cursor-pointer`}
                >
                  <span className="text-[23px] font-[600]">
                    {navItem.title}
                  </span>

                  <div className="ml-[8px] lg:hidden flex items-center justify-center">
                    <svg
                      strokeWidth={2}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="bi bi-arrow-right w-[22px] h-[22px]"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                      />
                    </svg>
                  </div>
                </li>
              ))}
          </ul>

          <ul
            className={`absolute text-white ${
              secondNav.selected ? "animation-fade-on" : "animation-fade-off"
            }  *:text-[#333] *:font-[500] w-[85%] md:w-[80%] py-[10px] lg:p-0 flex-col gap-7 pt-[1.15em] lg:pt-0 flex items-start lg:items-center justify-start list-none text-[30px] lg:text-[17px]`}
          >
            {secondNav.options &&
              secondNav.options.map((navItem, index) => (
                <li
                  onClick={(e) => {
                    goTo(secondNav.route, navItem.scrollTo);
                  }}
                  key={index}
                  className={`hover:text-[#333] flex items-center justify-center ${
                    !sideBar && "animate-fade-off hidden"
                  } lg:flex lg:visible duration-200 hover:cursor-pointer`}
                >
                  <span className="text-[23px] font-[600]">{navItem.name}</span>
                </li>
              ))}
          </ul>
        </nav>

        <button className="lg:hidden" onClick={(e) => setSideBar(!sideBar)}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 9h16.5m-16.5 6.75h16.5"
              />
            </svg>
          </span>
        </button>

        <ul
          ref={divRef}
          className="lg:flex hidden relative items-center justify-center px-2 w-auto h-full gap-10"
        >
          {navbar.map((navItem, index) => (
            <li
              key={index}
              className="relative flex items-center justify-center w-auto h-full text-[16px] font-[500]"
            >
              <button
                onClick={() => {
                  handleNavItemMouseEnter(navItem);
                  navItem.options.length === 0 && goTo(navItem.route);
                }}
              >
                <span className="text-[#000000cc]">{navItem.name}</span>
              </button>
              {currentNavItem && currentNavItem.name === navItem.name && (
                <span className="absolute z-[5] bottom-0 left-[50%] translate-y-[50%] -translate-x-[50%] flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    strokeWidth={20}
                    className="bi bi-chevron-up fill-black"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                    />
                  </svg>
                </span>
              )}
            </li>
          ))}
          {currentNavItem && (
            <div
              className="absolute z-10 flex items-start justify-start top-[60px] lg:top-[66px] left-0 w-full h-auto bg-white shadow-box"
              onMouseLeave={handleOptionMouseLeave}
            >
              <div className="title flex flex-col items-start justify-start py-4 gap-4 overflow-y-auto w-[35%] h-full border-r-[1.95px] border-[rgba(0, 0, 0, 0.05)]">
                {currentNavItem.options.map((option, index) => (
                  <button
                    key={index}
                    className={`flex items-center justify-start px-5 rounded-tl-none rounded-bl-none rounded-[10px] bg-transparent ${
                      currentOption &&
                      currentOption.title === option.title &&
                      "bg-[#F0F0F0]"
                    } hover:bg-[#F0F0F0] w-[90%] h-auto py-2`}
                    onClick={() => {
                      handleOptionClick(option);
                    }}
                  >
                    <span className="text-[16px] text-start font-[500] text-blue-baybow">
                      {option.title}
                    </span>
                  </button>
                ))}
              </div>
              <div className="option flex flex-col items-start justify-start w-[60%] h-full p-4">
                <ul className="flex flex-col items-start justify-start w-full h-full gap-4 overflow-y-auto">
                  {currentOption &&
                    currentOption.content.map((content, contentIndex) => (
                      <li key={contentIndex} className="text-[16px] font-[500]">
                        <button
                          onClick={(e) =>
                            goTo(currentOption.route, content.scrollTo)
                          }
                          className="flex items-center justify-start px-1 rounded-[10px] bg-transparent w-auto h-auto py-2"
                        >
                          <span className="text-[16px] text-start font-[500]">
                            {content.name}
                          </span>
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
        </ul>
      </div>
    </header>
  );
}

export default Navbar;
