import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { BentoGridApp } from "../components/BentoGrid";
import BentoGridAppSkeletoon from "../components/BentoGridAppSkeletoon";
import Slider from "../components/Slider";
import TopBar from "../components/TopBar";
import { getNews } from "../firebase/news/news";
import "../index.css";

export type content = {
  id: string;
  html: string;
  label: string;
  target: "head" | "body";
  image: ArrayBuffer | null;
  type: "Titre" | "Paragraphe" | "Image";
};

export type New = {
  id: string;
  head: content[];
  body: content[];
  publishedAt: Timestamp;
};

function News() {
  const [news, setNews] = useState<New[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getNews(setNews, setIsLoading);
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <TopBar />
      <section className="flex items-center justify-center w-full h-auto px-[1.25rem] py-section-small lg:py-section lg:px-[5.5em]">
        <div className="flex flex-col items-start justify-start w-full md:w-[95%] lg:w-full h-auto">
          <div className="w-full h-[32.5vh] md:h-[400px] mb-5">
            <Slider news={news} isLoading={isLoading} />
          </div>

          <div className="w-full h-auto mt-4">
            <h1 className="mb-3 text-[23px] lg:text-[25px] font-[500]">
              Toutes les actualités
            </h1>

            {isLoading ? (
              <BentoGridAppSkeletoon />
            ) : (
              <BentoGridApp news={news} />
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default News;
