import { User } from "../../sections/ContactUs";
import axios from "axios";

async function getUserIPAddress(): Promise<string | undefined> {
  try {
    const response = await axios.get<{ ip: string }>(
      "https://api.ipify.org/?format=json"
    );
    return response.data.ip;
  } catch (error) {
    return undefined;
  }
}

export const sendMail = async (
  user: User,
  recaptcha: string,
  setRecaptcha: React.Dispatch<React.SetStateAction<string | null>>,
  activeToast: (message: string, success: boolean) => void,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const ipAddress = await getUserIPAddress();

  if (!ipAddress) {
    activeToast(
      "Une erreur est survenue. Veuillez re-essayer plus tard.",
      false
    );
    setIsLoading(false);
    return;
  }

  try {
    const response = await axios.post(
      "https://api.intellifrica.com/mail/contact",
      {
        email: user.email,
        firstName: user.nom,
        lastName: user.prenom,
        message: user.message,
        number: user.numeroDeTelephone,
        ip: ipAddress,
      },
      {
        headers: {
          Authorization: `Bearer ${recaptcha}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.data.code === "successfull") {
      activeToast("Nous avons bien recu votre message.", true);
    }
  } catch (error) {
    activeToast(
      "Une erreur est survenue. Veuillez re-essayer plus tard.",
      false
    );
  } finally {
    setRecaptcha(null);
    setIsLoading(false);
  }
};
