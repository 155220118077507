import React, { useEffect } from "react";
import "../index.css";
import World from "../assets/images/world.jpg";
import TopBar from "../components/TopBar";
import { useLocation } from "react-router-dom";
import Chevron from "../components/Chevron";
import Footer from "../components/Footer";

function AboutUs() {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollto");

    if (scrollTo) {
      const element = document.getElementById(scrollTo);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - 60,
          behavior: "smooth",
        });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <div className="mt-[-75px] lg:mt-[-85px]">
      <TopBar />
      <Chevron />

      <section className="flex items-center justify-center">
        <div className="flex flex-col items-center justify-center w-full h-auto">
          <div
            id="who-are-we"
            className="about-us bg-white flex md:justify-center w-full min-h-screen py-section-small md:py-section"
          >
            <div className="relative flex md:hidden mb-[1.75em] z-[1] md:mb-0 image-bg-about-us w-full h-[65vh]">
              <img
                className="w-full h-full object-cover"
                src="https://i.pinimg.com/736x/9c/24/df/9c24df984057693d2f137546f3ee892a.jpg"
                alt=""
              />
            </div>
            <div className="w-[85%] z-10 self-center flex items-center justify-between">
              <div className="text max-w-full md:max-w-[45%]">
                <span className="text-[18px] text-blue-baybow">
                  À propos de nous
                </span>
                <h2 className="text-[30px] mt-[6px] mb-[16px] md:text-[45px] font-[600]">
                  Notre objectif, la démocratisation du self-service banking
                </h2>
                <p className="text-[16px] md:text-[17px] text-start md:text-balance text-gray-500">
                  Membre du Groupe Burval, Baybow stimule l'innovation et
                  renforce l'accessibilité du self-service banking. Nous offrons
                  des solutions sur-mesure alliant performance, sécurité et
                  proximité. Notre mission est de favoriser l'inclusion
                  financière et d'accélérer la transformation digitale du
                  secteur bancaire en Afrique.
                </p>
              </div>

              <div className="hidden md:flex shape relative max-w-[50%] h-auto items-center justify-center">
                <div className="shape z-20 asbolute w-[250px] h-[250px] lg:w-[400px] lg:h-[400px]"></div>
                <div className="only-shape z-10 bg-gray-200 absolute w-[250px] h-[250px] lg:w-[400px] lg:h-[400px]"></div>
                {/*  */}
              </div>
            </div>
          </div>

          <div
            id="our-presence"
            className="our-presence flex flex-col items-center justify-center w-[85%] py-section-small md:py-section"
          >
            <div className="text flex flex-col items-center justify-center">
              <h2 className="text-center text-[30px] md:text-[45px] font-[500] mb-5">
                Notre réseau SAV
              </h2>

              <p className="text-center text-[16px] md:text-[18px] text-gray-500 w-full md:w-[55%]">
                Filiale du groupe Burval, nous bénéficions d'un important réseau
                nous permettant d'intervenir dans de nombreux pays.
              </p>

              {/* <ul className="w-full h-auto my-[3em] flex flex-col md:flex-row items-center justify-between">
                <li className="flex flex-col items-center md:items-start justify-center h-full px-8 py-3 md:py-0 border-b-0 md:border-r-[1.5px] border-gray-300 md:mb-0 w-full md:w-auto">
                  <h3 className="text-[#1D1D1D] gradient text-[3rem] font-[500]">
                    75k
                  </h3>
                  <p className="text-[16px] text-[#c4c4c4] font-[400] text-center md:text-start mt-2 md:mt-0">
                    Clients à travers l'Afrique
                  </p>
                </li>
                <li className="flex flex-col items-center md:items-start justify-center h-full px-8 py-3 md:py-0 border-b-0 md:border-r-[1.5px] border-gray-300 md:mb-0 w-full md:w-auto">
                  <h3 className="text-[#1D1D1D] gradient text-[3rem] font-[500]">
                    75k
                  </h3>
                  <p className="text-[16px] text-[#c4c4c4] font-[400] text-center md:text-start mt-2 md:mt-0">
                    Clients à travers l'Afrique
                  </p>
                </li>
                <li className="flex flex-col items-center md:items-start justify-center h-full px-8 py-3 md:py-0 border-b-0 md:border-r-[1.5px] border-gray-300 md:mb-0 w-full md:w-auto">
                  <h3 className="text-[#1D1D1D] gradient text-[3rem] font-[500]">
                    75k
                  </h3>
                  <p className="text-[16px] text-[#c4c4c4] font-[400] text-center md:text-start mt-2 md:mt-0">
                    Clients à travers l'Afrique
                  </p>
                </li>
                <li className="flex flex-col items-center md:items-start justify-center h-full px-8 py-3 md:py-0 border-b-0 md:border-r-[1.5px] border-gray-300 md:mb-0 w-full md:w-auto">
                  <h3 className="text-[#1D1D1D] gradient text-[3rem] font-[500]">
                    75k
                  </h3>
                  <p className="text-[16px] text-[#c4c4c4] font-[400] text-center md:text-start mt-2 md:mt-0">
                    Clients à travers l'Afrique
                  </p>
                </li>
              </ul> */}
            </div>

            <div className="flex items-center justify-center mt-[3em] w-full md:max-w-[75%] overflow-hidden">
              <img
                className="w-full h-full object-cover"
                // src={World}
                src="https://firebasestorage.googleapis.com/v0/b/baybow-1768d.appspot.com/o/world.jpg?alt=media&token=19c0e110-c23c-4f71-97a8-f0004fd1b5b9"
                alt="Map of the world"
              />
            </div>
          </div>

          <div
            id="our-team"
            className="our-team flex items-center justify-center w-full bg-gray-100 py-section-small md:py-section"
          >
            <div className="w-[85%] flex flex-col items-center justify-center">
              <div className="text flex flex-col items-center justify-center">
                <h2 className="text-center text-[30px] md:text-[45px] font-[500] mb-5">
                  Notre équipe
                </h2>
                <p className="text-center text-[16px] md:text-[17px] text-gray-500 w-full md:w-[55%]">
                  Notre équipe est composée de professionnels passionnés,
                  spécialisés dans leurs domaines respectifs, et engagés à
                  fournir des solutions innovantes et un service client
                  exceptionnel.
                </p>
              </div>

              <div className="team flex items-center justify-center w-full h-auto mt-12">
                <div className="w-full max-w-[800px] h-[250px] h-full overflow-hidden">
                  {/* <img
                    className="w-full h-full object-cover"
                    src="https://firebasestorage.googleapis.com/v0/b/brocand-test.appspot.com/o/movies%2Fbaybow_together.jpg?alt=media&token=5585b0ed-312f-45d4-8ccd-009c1244e72c"
                    alt="Baybow Together"
                  /> */}
                </div>
                {/* <ul className="flex flex-col md:flex-row items-center justify-between gap-10 w-full md:w-auto h-auto md:h-[400px]">
                  <li className="relative bg-white duration-200 card-profil overflow-hidden w-full max-w-[280px] md:w-[200px] lg:w-[240px] h-[300px] md:h-full flex flex-col items-center justify-end">
                    <img
                      className="absolute w-full h-full z-[1] object-cover"
                      src="https://purepng.com/public/uploads/large/purepng.com-thinking-manthinking-manpersongentle-men-thinkingthinking-brain-1421526976458gpxqy.png"
                      alt="Mohamed Amadou"
                    />
                    <div className="absolute z-[2] w-full h-full bg-[#00000027]"></div>

                    <div className="min-w-full z-[2] card-profil-text-vert flex items-end justify-end px-[2em]">
                      <h3 className="text-white w-full text-[20px] md:text-[30px] font-medium transform rotate-90">
                        Mohamed Amadou
                      </h3>
                    </div>

                    <div className="z-[3] card-profil-text hidden flex-col items-start justify-start w-[85%] h-auto py-[1.25em]">
                      <h3 className="text-white text-[20px] md:text-[25px] font-medium">
                        Mohamed Amadou
                      </h3>
                      <span className="text-[14px] md:text-[17px] text-white">
                        CEO
                      </span>
                    </div>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
