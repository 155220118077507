import React from "react";
import NewGrid, { BentoGridSkeletoon } from "./NewGrid";

function BentoGridAppSkeletoon() {
  return (
    <div className="min-w-full mx-auto">
      <BentoGridSkeletoon className="*:hover:cursor-pointer row-span-1 rounded-xl group/bento transition duration-200 dark:shadow-none py-4 dark:bg-black dark:border-white/[0.2] bg-white justify-between flex flex-col space-y-4">
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid /> <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid /> <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid /> <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid /> <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
        <NewGrid />
      </BentoGridSkeletoon>
    </div>
  );
}

export default BentoGridAppSkeletoon;
