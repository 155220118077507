import React, { useEffect, useState } from "react";
import BayBowLogo from "../assets/images/logos/baybow.png";
import { Timestamp } from "firebase/firestore";
import { getNew } from "../firebase/news/news";
import { useNavigate, useParams } from "react-router-dom";
import "../index.css";
import Footer from "../components/Footer";
import Chevron from "../components/Chevron";
import TopBar from "../components/TopBar";

function parseHtml(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

function formatFirebaseTimestamp(timestamp: Timestamp): string {
  const date = new Date(timestamp.seconds * 1000);

  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  const days = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];

  const day = days[date.getUTCDay()];
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const formattedDate = `${date.getUTCDate()} ${month} ${year} à ${hours}h${minutes} GMT`;

  return formattedDate;
}

function New() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [topFiveNews, setTopFiveNews] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentNew, setCurrentNew] = useState<any | null>(null);

  useEffect(() => {
    id && getNew(id, setCurrentNew, setTopFiveNews, setIsLoading);
  }, [id]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  useEffect(() => {
    if (isLoading) {
      window.document.body.style.overflow = "hidden";
    } else if (isLoading === false) {
      window.document.body.style.overflow = "auto";
    }
  }, [isLoading]);

  function convertToStr(value: string | ArrayBuffer | null) {
    if (typeof value === "string") {
      return value;
    } else if (isArrayBuffer(value)) {
      const blob = new Blob([value]);
      const url = URL.createObjectURL(blob);
      return url;
    } else {
      return "no-image.png";
    }
  }

  const isArrayBuffer = (value: any): value is ArrayBuffer => {
    return (
      value &&
      typeof ArrayBuffer !== "undefined" &&
      value instanceof ArrayBuffer
    );
  };

  return (
    <div className="merriweather-regular">
      <Chevron />
      <TopBar />

      <section className="w-full h-auto lg:h-screen flex flex-col lg:flex-row justify-center">
        {isLoading && (
          <div className="shadow absolute animate-pulse w-full h-[55vh] lg:h-full z-50 bg-gray-300"></div>
        )}

        <div className="image w-full h-[55vh] lg:h-full overflow-hidden z-[-1] lg:absolute">
          <img
            src={currentNew && convertToStr(currentNew.head[2].image)}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="shadow hidden lg:flex absolute w-full h-[55vh] lg:h-full z-[1] bg-[#000000a7]"></div>
        {!isLoading && (
          <div className="relative flex items-center justify-start py-7 border-b-[1.15px] border-b-[#e3e5e8] lg:mt-0 w-full lg:w-[90%] h-full px-[1.25rem] lg:px-0">
            <a href="#read">
              <button className="absolute bottom-10 z-[2] border-[2px] border-white px-4 py-2 rounded-full flex items-center justify-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[22px] h-[22px] text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </button>
            </a>
            <div className="text w-full lg:w-[60%] z-[2] text-black lg:*:text-white">
              <span className="text-[14px] mb-2">
                Publié le{" "}
                {currentNew && formatFirebaseTimestamp(currentNew.publishedAt)}.
              </span>
              {currentNew && (
                <h1
                  className="md:*:text-[1.25em] lg:*:text-[3.25rem] *:font-[600]"
                  dangerouslySetInnerHTML={{ __html: currentNew.head[0].html }}
                ></h1>
              )}
              {currentNew && (
                <p
                  className="*:text-[18px] *:font-[500] mt-3"
                  dangerouslySetInnerHTML={{ __html: currentNew.head[1].html }}
                ></p>
              )}
            </div>
          </div>
        )}
      </section>

      <section
        id="read"
        className="flex items-start justify-center w-full h-auto"
      >
        <div className="flex flex-col lg:flex-row items-start justify-between w-full lg:w-[90%] px-[1.25rem] lg:px-0">
          <article className="flex flex-col items-start justify-start w-full lg:w-[65%] h-auto py-section-small lg:py-section">
            {currentNew?.body?.map((item: any, index: number) => (
              <div key={item.id} className="w-auto">
                {item.type === "Titre" && (
                  <h3
                    key={item.id}
                    className={`w-full lg:w-[80%] ${
                      index !== 0 && "mt-[2.5rem]"
                    }  mb-[1rem] *:font-[600] *:text-[1.75em]`}
                    dangerouslySetInnerHTML={{ __html: item.html }}
                  ></h3>
                )}
                {item.type === "Paragraphe" && (
                  <p
                    key={item.id}
                    className="w-full *:text-[16px] lg:*:text-[18px] text-pretty lg:w-[80%] mb-[1rem]"
                    dangerouslySetInnerHTML={{ __html: item.html }}
                  />
                )}
              </div>
            ))}
          </article>

          {!isLoading && (
            <article className="sticky top-[60px] lg:top-[66px] w-full lg:w-[30%] py-7 border-t-[1.15px] lg:border-t-0 border-t-[#e3e5e8]">
              <h3 className="text-[15px] font-[600] mb-5 text-black">
                A voir aussi
              </h3>

              <ul className="flex flex-col items-start justify-start">
                {topFiveNews?.map(
                  (data, index) =>
                    id &&
                    id !== data.id && (
                      <li
                        onClick={(e) => navigate(`/new/${data.id}`)}
                        key={index}
                        className="last:border-b-0 flex items-start justify-start gap-3 w-full py-3 border-b-[1.25px] border-[#c5c7ca]"
                      >
                        <div className="flex text-[35px] leading-[37px] text-blue-baybow font-[600]">
                          {index + 1}
                        </div>
                        <span className="hover:underline hover:cursor-pointer duration-400 text-[16px] leading-[22px] text-black font-[600]">
                          {parseHtml(data.head[0].html)}
                        </span>
                      </li>
                    )
                )}
              </ul>
            </article>
          )}
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default New;
