import React from "react";
import BayBowLogo from "../assets/images/logos/baybow.png";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <section className="flex flex-col items-center justify-center bg-[#F1F4F8] w-full h-auto">
      <div className="flex flex-col md:flex-row items-start justify-start md:items-center md:justify-center w-[85%] h-auto py-section-small md:py-section">
        <div className="w-[70%] md:w-[35%] h-auto overflow-hidden mr-10">
          <img className="w-full h-full object-cover" src={BayBowLogo} alt="" />
        </div>

        <div className="flex flex-col items-start justify-start w-full md:max-w-[55%]">
          <h2 className="text-[1.65rem] md:text-[45px] text-start font-[500] text-black mb-4">
            Soyez digital plus que jamais
          </h2>
          <a
            href="https://baybow.asia/contact-us"
            target="_blank"
            rel="noreferrer"
          >
            <button className="flex items-center justify-center rounded-[10px] px-[16px] py-[9px] bg-transparent border-[1.85px] border-blue-baybow">
              <span className="text-[15px] md:text-[17px] text-black font-[500]">
                C'est parti !
              </span>
            </button>
          </a>
        </div>
        {/* <div className="flex flex-col items-start justify-start w-full text-start py-2">
          <div className="flex flex-wrap items-center justify-start gap-2 w-full h-auto py-2">
            <span className="text-[15px] text-black">contact@baybow.com</span>
            <span>·</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-linkedin fill-[#0a66c2] w-[23px] h-[23px]"
                viewBox="0 0 16 16"
              >
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
              </svg>
            </span>
          </div>
          <span className="text-[14px] *:text-[#242424]">
            &copy; Baybow 2024 Powered By{" "}
            <a href="mailto:intellifrica.contact@gmail.com">Intellifrica</a>
          </span>
        </div> */}
      </div>
      <div className="w-full text-center py-2 bg-white">
        <span className="text-[14px] text-[#242424]">
          &copy; Baybow 2024 – Powered By{" "}
          <a
            className="text-blue-600 underline"
            href="mailto:intellifrica@gmail.com"
          >
            Intellifrica
          </a>
        </span>
      </div>
    </section>
  );
}

export default Footer;
